import { isAcceptedLang } from './isAcceptedLang';
import { type AcceptedLang, LANG_DEFAULT, type UrlLang } from './models';

export const getUrlLang = (url: string): string | undefined => {
    return url.split('/')[3];
};

export const getLang = (): AcceptedLang => {
    const urlLang = getUrlLang(window.location.href);
    const mappedLang = mapUrlToLangCode(urlLang || LANG_DEFAULT);

    return isAcceptedLang(mappedLang) ? mappedLang : LANG_DEFAULT;
};

export const mapUrlToLangCode = (urlLangCode: string): AcceptedLang => {
    return langCodeFromURLMapping[urlLangCode as UrlLang] || urlLangCode;
};

const langCodeFromURLMapping: Record<UrlLang, AcceptedLang> = {
    en: 'en',
    de: 'de',
    ru: 'ru',
    fr: 'fr',
    es: 'es',
    it: 'it',
    pl: 'pl',
    tr: 'tr',
    br: 'pt_BR',
};

const langCodeToURLMapping: Record<AcceptedLang, UrlLang> = {
    en: 'en',
    de: 'de',
    ru: 'ru',
    fr: 'fr',
    es: 'es',
    it: 'it',
    pl: 'pl',
    tr: 'tr',
    pt_BR: 'br',
};

export const toUrlLang = (lang: AcceptedLang): UrlLang => {
    return langCodeToURLMapping[lang];
};

export const getDashLang = () => {
    const lang = getLang();
    const replaced = replaceUnderscore(lang);

    return replaced;
};

type Replace<
    T extends string,
    S extends string,
    D extends string,
    A extends string = '',
> = T extends `${infer L}${S}${infer R}` ? Replace<R, S, D, `${A}${L}${D}`> : `${A}${T}`;

const replaceUnderscore = <T extends string>(value: T): Replace<T, '_', '-'> => {
    return value.replace('_', '-') as Replace<T, '_', '-'>;
};
