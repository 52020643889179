import type { UserResponse } from '@lingoda/api';
import { combineActions, handleActionsImmutably } from '@lingoda/core';
import {
    createStudent,
    fetchMe,
    logout,
    removeCurrentUser,
    setCurrentUser,
    setStatus,
} from '../actions';
import { AuthStatus } from '../models';
import type { Auth } from '../models';

export type State = Auth;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default handleActionsImmutably<State, any>(
    {
        [`${setCurrentUser}`]: (state, { payload }: ReturnType<typeof setCurrentUser>) => {
            state.userId = payload;

            return state;
        },
        [`${removeCurrentUser}`]: (state) => {
            delete state.userId;

            return state;
        },
        [`${setStatus}`]: (state, { payload }: ReturnType<typeof setStatus>) => {
            state.status = payload;

            return state;
        },
        [`${fetchMe}`]: (state) => {
            state.status = AuthStatus.Unknown;

            return state;
        },
        [`${combineActions(fetchMe.success, createStudent.success)}`]: (
            state,
            action: ReturnType<typeof fetchMe.success | typeof createStudent.success>,
        ) => {
            state.status = AuthStatus.Authenticated;
            state.userId = (action.payload.result as UserResponse).data.id;

            return state;
        },
        [`${combineActions(fetchMe.failure, logout)}`]: (state) => {
            state.status = AuthStatus.Unauthenticated;
            state.userId = undefined;

            return state;
        },
    },
    {},
);
