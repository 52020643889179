import {
    createDate,
    differenceInMilliseconds,
    getHours,
    getMinutes,
    set,
    toUTCString,
} from '@lingoda/dates';
import { type Callback } from './types';

export const CLOCK_INTERVALS = ['second', 'minute', 'halfHour', 'hour', 'halfDay', 'day'] as const;

export type ClockInterval = (typeof CLOCK_INTERVALS)[number];

export const intervalCalls: Partial<Record<ClockInterval, Callback[]>> = {};

export const addClockInterval = (clockInterval: ClockInterval, callback: () => void) => {
    intervalCalls[clockInterval] = [...(intervalCalls[clockInterval] || []), callback];
};

export const clearClockInterval = (clockInterval: ClockInterval, callback: () => void) => {
    intervalCalls[clockInterval] = (intervalCalls[clockInterval] || []).filter(
        (handler) => handler !== callback,
    );
};

export const clearClockIntervals = () => {
    Object.keys(intervalCalls).forEach((key) => {
        delete intervalCalls[key as ClockInterval];
    });
};

const getLastIntervalTime = (clockInterval: ClockInterval, now: Date) => {
    switch (clockInterval) {
        case 'second':
            return set({ milliseconds: 0 }, now);
        case 'minute':
            return set({ seconds: 0, milliseconds: 0 }, now);
        case 'halfHour':
            return set(
                { minutes: getMinutes(now) >= 30 ? 30 : 0, seconds: 0, milliseconds: 0 },
                now,
            );
        case 'hour':
            return set({ minutes: 0, seconds: 0, milliseconds: 0 }, now);
        case 'halfDay':
            return set(
                { hours: getHours(now) >= 12 ? 12 : 0, minutes: 0, seconds: 0, milliseconds: 0 },
                now,
            );
        case 'day':
            return set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }, now);
    }
};

const initUTCTime = toUTCString(createDate());

const lastCallTime: Record<ClockInterval, string> = {
    second: initUTCTime,
    minute: initUTCTime,
    halfHour: initUTCTime,
    hour: initUTCTime,
    halfDay: initUTCTime,
    day: initUTCTime,
};

export const initIntervalLastCallTimes = (now: Date) => {
    CLOCK_INTERVALS.forEach((clockInterval) => {
        lastCallTime[clockInterval] = toUTCString(getLastIntervalTime(clockInterval, now));
    });
};

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;

const intervalDiffs: Record<ClockInterval, number> = {
    second: ONE_SECOND,
    minute: ONE_MINUTE,
    halfHour: ONE_HOUR / 2,
    hour: ONE_HOUR,
    halfDay: ONE_DAY / 2,
    day: ONE_DAY,
};

const triggerClockInterval = (clockInterval: ClockInterval, callbacks: Callback[], now: Date) => {
    const maxDiffFromLastCall = intervalDiffs[clockInterval];
    const actualDiffFromLastCall = differenceInMilliseconds(
        now,
        createDate(lastCallTime[clockInterval]),
    );

    if (actualDiffFromLastCall >= maxDiffFromLastCall) {
        lastCallTime[clockInterval] = toUTCString(getLastIntervalTime(clockInterval, now));
        callbacks.forEach((callback) => callback());
    }
};

export const triggerClockIntervals = (now: Date) => {
    Object.entries(intervalCalls).forEach(([clockInterval, callbacks]) =>
        triggerClockInterval(clockInterval as ClockInterval, callbacks, now),
    );
};
