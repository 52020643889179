import { createElement } from 'react';
import type { FC } from 'react';

interface ScalarParams {
    [key: string]: string;
}

export interface Params {
    [key: string]: ParamValue;
}

export interface Props {
    id: string;
    params?: Params;
    domain: string;
    component?: React.ElementType;
}

const isFunctionalComponent = (param: unknown): param is FC => typeof param === 'function';
const isPlaceholder = (text: string) => text[0] === '%' && text[text.length - 1] === '%';
export const isPossibleHtml = (text: string) => text.indexOf('<') !== -1;

export type ParamValue = React.ReactElement<Record<string, unknown>> | string | number;

export const filterStringParams = (params: Params | undefined): ScalarParams => {
    const stringParams: ScalarParams = {};
    for (const name in params) {
        if (
            params.hasOwnProperty(name) &&
            typeof params[name] !== 'object' &&
            typeof params[name] !== 'function'
        ) {
            stringParams[name] = `${params[name]}`;
        }
    }

    return stringParams;
};

const renderPlaceHolder = (placeholder: string, params?: Params) => {
    if (isPlaceholder(placeholder)) {
        const name = placeholder.substr(1, placeholder.length - 2);
        const param = params?.[name];

        return isFunctionalComponent(param) ? createElement(param, {}, []) : param;
    }

    return placeholder;
};

export const renderPlaceholders = (translation: string, params?: Params) =>
    translation
        .split(/(%\w+?%)/)
        .filter((slice) => slice !== '')
        .map((slice) => renderPlaceHolder(slice, params));
