import request from './request';
import type { JsonSerializable } from './types';

export const httpPatch = <T>(
    url: string,
    data?: Record<string, JsonSerializable> | JsonSerializable[],
): Promise<T> =>
    request(url, {
        method: 'PATCH',
        body: JSON.stringify(data),
    });
