import { createRecordFactory } from '@lingoda/utils';

export const STATUS_PENDING = 'pending';
export const STATUS_SUCCESS = 'success';
export const STATUS_FAILED = 'failed';

export type RequestId = string;

export interface RequestAttributes {
    id: RequestId;
    actionType?: string;
    status: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response?: any;
}

export type Request = Readonly<RequestAttributes>;

export const RequestRecord = createRecordFactory<Request>({
    id: undefined,
    actionType: undefined,
    status: undefined,
    response: undefined,
});
