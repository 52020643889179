import type { GoogleWindow, Props } from '../common';

declare const window: GoogleWindow;

export default (props: Props) => {
    if (window && window.ga) {
        window.ga('set', props);

        return true;
    }
};
