import isReloadRequired from './isReloadRequired';
import resolvePath from './resolvePath';
import type { Path } from './types';

export default (path?: Path) => {
    if (!path) {
        return {};
    }

    return {
        href: path && resolvePath(path),
        target: path && isReloadRequired(path) ? '_self' : undefined,
    };
};
