import { type GraphQLErrors, isApolloError as isApolloErrorOriginal } from '@apollo/client/errors';
import type { ApolloError, ServerError } from '@apollo/client';

export const getGraphQLErrorsFromApollo = (apolloError: ApolloError): GraphQLErrors => {
    const populatedErrors = apolloError.graphQLErrors;

    if (populatedErrors && populatedErrors.length > 0) {
        return populatedErrors;
    }

    return getGraphQLErrorsFromNetwork(apolloError.networkError);
};

interface GraphQLNetworkServerError {
    networkError: ServerError;
}

export const isGqlNetworkServerError = (error: unknown): error is GraphQLNetworkServerError => {
    const castedError = error as Partial<GraphQLNetworkServerError> | undefined;

    return !!castedError?.networkError?.statusCode;
};

export const getGraphQLErrorsFromNetwork = (
    networkError: ApolloError['networkError'] | undefined,
): GraphQLErrors => {
    // In some cases `graphQLErrors` are not populated and placed inside `networkError` instead
    // see Apollo bug: https://github.com/apollographql/apollo-client/issues/8503
    const networkErrorResult = (networkError as ServerError | null)?.result;
    if (typeof networkErrorResult === 'string') {
        return [];
    }

    const unpopulatedErrors = networkErrorResult?.errors;
    if (Array.isArray(unpopulatedErrors) && unpopulatedErrors.length > 0) {
        return unpopulatedErrors;
    }

    return [];
};

export const getApolloErrorUiMessage = (apolloError: ApolloError): string | undefined => {
    const graphQLErrors = getGraphQLErrorsFromApollo(apolloError);

    if (graphQLErrors.length > 0) {
        const firstError = graphQLErrors[0];

        if (process.env.NODE_ENV === 'production') {
            if (shouldMaskGqlError(firstError)) {
                return;
            }
        }

        return firstError.message;
    }

    if (apolloError.networkError) {
        const serverError = apolloError.networkError as ServerError;

        if (process.env.NODE_ENV === 'production') {
            if (serverError?.statusCode && shouldMaskNetworkError(serverError.statusCode)) {
                return;
            }
        }

        const result = serverError?.result;

        return typeof result === 'string' ? result : 'Network error';
    }

    return apolloError.message;
};

const shouldMaskGqlError = (gqlError: GraphQLErrors[0]) => {
    if (gqlError.extensions?.code === 'INTERNAL_SERVER_ERROR') {
        return true;
    }

    return false;
};

const shouldMaskNetworkError = (statusCode: number) => {
    if (statusCode >= 500) {
        return true;
    }

    return false;
};

export const isApolloError = (error: unknown): error is ApolloError => {
    return error instanceof Error && isApolloErrorOriginal(error);
};

const getGqlValidationErrors = (error: ApolloError) => {
    const errors = getGraphQLErrorsFromApollo(error);

    return errors.filter((error) => error.extensions?.category === 'Validate');
};

export const getValidationErrorMessages = (error: ApolloError): Record<string, string> => {
    const validationErrors = getGqlValidationErrors(error)
        .map((error) => ({
            field: error.extensions.field as string,
            message: error.message,
        }))
        .reduce((acc, { field, message }) => ({ ...acc, [field]: message }), {});

    return validationErrors;
};

export const getValidationErrorCodes = (error: ApolloError): Record<string, string> => {
    const validationErrors = getGqlValidationErrors(error)
        .map((error) => ({
            field: error.extensions.field as string,
            code: error.extensions.code,
        }))
        .reduce((acc, { field, code }) => ({ ...acc, [field]: code }), {});

    return validationErrors;
};
