import type { GoogleWindow } from '../common';

declare const window: GoogleWindow;

interface Params {
    [key: string]: string | boolean | number | undefined | ((value: unknown) => void);
}

export default (params?: Params) => {
    if (window) {
        try {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(params);
        } catch {
            // Sometimes for some reason the above code fails if something wasn't loaded in GTM
            // so we just guard against that.
        }
    }
};
