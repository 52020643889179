import { v4 as uuidV4 } from 'uuid';
import { createAction } from './createAction';
import type { Action, ActionMeta } from './createAction';

type ActionFunction1<T1, R> = (t1: T1) => R;

export interface Extra {
    meta: {
        id: string;
    };
}

type CallbackAction<Payload, Result, Meta> = ActionFunction1<
    { payload: Payload; result: Result; meta: Meta },
    Action<{ payload: Payload; result: Result; meta: Meta }>
>;

export interface LifecycleActionCreator<
    P,
    M,
    A extends Action<P>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends (...args: any[]) => A,
> {
    (...args: Parameters<F>): ReturnType<F> & Extra;
    success: CallbackAction<P, unknown, M>;
    failure: CallbackAction<P, unknown, M>;
}

export const isActionTrackable = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    creator: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): creator is LifecycleActionCreator<any, any, any, any> =>
    creator && !!creator.success && !!creator.failure;

export const makeActionTrackable = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    F extends (...args: any[]) => any,
>(
    actionCreator: F,
) => {
    type A = ReturnType<F>;
    type Payload = A extends Action<infer Pl> ? Pl : never;
    type Meta = A extends ActionMeta<unknown, infer M> ? M : never;
    const newActionCreator: LifecycleActionCreator<Payload, Meta, A, F> = (...args) => {
        const action = actionCreator(...args);
        action.meta = action.meta || {};
        action.meta.id = uuidV4();

        return action;
    };

    newActionCreator.success = createAction(`${actionCreator}_SUCCESS`);
    newActionCreator.failure = createAction(`${actionCreator}_FAILURE`);
    newActionCreator.toString = () => actionCreator.toString();

    return newActionCreator;
};
