import { useMemo } from 'react';
import { UiTrackingContext } from '../context';
import { useTrackingContext } from '../hooks';

interface Props {
    section: string;
}

export const TrackingContext = ({ children, section }: React.PropsWithChildren<Props>) => {
    const trackingContext = useTrackingContext();
    const value = useMemo(
        () => ({
            sections: [section, ...trackingContext.sections],
            metadata: {},
        }),
        [section, trackingContext.sections],
    );

    return <UiTrackingContext.Provider value={value}>{children}</UiTrackingContext.Provider>;
};
