import { sectionsTranslationMapping } from '@lingoda/config';
import { getLang } from '@lingoda/env';
import type { SectionName } from '@lingoda/sections';

export const getTranslatedSectionRouting = (
    sectionName: SectionName | undefined,
    locale = getLang(),
) => {
    if (sectionName) {
        return sectionsTranslationMapping[sectionName][locale];
    }

    return sectionName || '';
};
