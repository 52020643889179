import { type AcceptedLang } from '@lingoda/env';

export type SectionsMapping = Record<SectionName, Record<AcceptedLang, SectionNameTranslation>>;

export type SectionNameTranslation =
    // en:
    | 'english'
    | 'englisch'
    | 'ingles'
    | 'anglais'
    | 'angielski'
    | 'inglese'
    | 'ingilizce'
    // de:
    | 'german'
    | 'deutsch'
    | 'aleman'
    | 'alemao'
    | 'allemand'
    | 'niemiecki'
    | 'tedesco'
    | 'almanca'
    // fr:
    | 'french'
    | 'franzoesisch'
    | 'frances'
    | 'francais'
    | 'francuski'
    | 'francese'
    | 'fransizca'
    // es:
    | 'spanish'
    | 'spanisch'
    | 'espanol'
    | 'espanhol'
    | 'espagnol'
    | 'hiszpanski'
    | 'ispanyolca'
    | 'spagnolo'
    // it:
    | 'italian'
    | 'italienisch'
    | 'italiano'
    | 'italien'
    | 'italyan'
    | 'wloski';

export const sectionsTranslationMapping: SectionsMapping = {
    english: {
        en: 'english',
        de: 'englisch',
        es: 'ingles',
        fr: 'anglais',
        ru: 'english',
        pt_BR: 'ingles',
        pl: 'angielski',
        it: 'inglese',
        tr: 'ingilizce',
    },
    german: {
        en: 'german',
        de: 'deutsch',
        es: 'aleman',
        fr: 'allemand',
        ru: 'german',
        pt_BR: 'alemao',
        pl: 'niemiecki',
        it: 'tedesco',
        tr: 'almanca',
    },
    french: {
        en: 'french',
        de: 'franzoesisch',
        es: 'frances',
        fr: 'francais',
        ru: 'french',
        pt_BR: 'frances',
        pl: 'francuski',
        it: 'francese',
        tr: 'fransizca',
    },
    spanish: {
        en: 'spanish',
        de: 'spanisch',
        es: 'espanol',
        fr: 'espagnol',
        ru: 'spanish',
        pt_BR: 'espanhol',
        pl: 'hiszpanski',
        it: 'spagnolo',
        tr: 'ispanyolca',
    },
    italian: {
        en: 'italian',
        de: 'italienisch',
        es: 'italiano',
        fr: 'italien',
        ru: 'italian',
        pt_BR: 'italiano',
        pl: 'wloski',
        it: 'italiano',
        tr: 'italyan',
    },
};
