import { createTheme } from '@mui/material/styles';
import { type Typography } from './models';
import { breakpoints } from './breakpoints';

const { typography: baseTypography } = createTheme();

export const baseFontSize = 16;

const body1Typography = {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    letterSpacing: -0.1,
};

export const typographyXs = {
    h1: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
} satisfies Partial<Typography>;

export const typography: Typography = {
    ...baseTypography,
    fontFamily: 'Open Sans',
    fontSize: baseFontSize,
    h1: {
        [breakpoints.only('xs')]: {
            ...typographyXs.h1,
        },
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: '2.75rem',
    },
    h2: {
        [breakpoints.only('xs')]: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
        },
        fontSize: '1.5rem',
        fontWeight: 600,
        lineHeight: '2rem',
    },
    h3: {
        [breakpoints.only('xs')]: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
        },
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: '1.75rem',
    },
    h4: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.375rem',
    },
    h5: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.125rem',
    },
    h6: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.375rem',
    },
    subtitle1: {
        fontSize: '1rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
        letterSpacing: -0.1,
    },
    subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.25rem',
        letterSpacing: -0.1,
    },
    body1: body1Typography,
    body2: {
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
        letterSpacing: -0.1,
    },
    body3: {
        fontSize: '0.75rem',
        fontWeight: 600,
        lineHeight: '1rem',
        letterSpacing: 0.2,
    },
    body4: {
        ...body1Typography,

        [breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        },
    },
    button: {
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.25rem',
        textTransform: 'none',
    },
    caption: {
        fontSize: '0.625rem',
        fontWeight: 400,
        lineHeight: '0.875rem',
        letterSpacing: 0.1,
    },
    overline: {
        fontSize: '0.75rem',
        fontWeight: 600,
        letterSpacing: 0.1,
        lineHeight: '1.125rem',
        textTransform: 'uppercase',
    },
    smallest: {
        fontSize: '0.5rem',
        fontWeight: 600,
        lineHeight: '0.75rem',
        letterSpacing: 0.1,
    },
};
