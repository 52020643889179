import { createRecordFactory } from '@lingoda/utils';
import type { SectionCurriculaQuery } from '@lingoda/graphql';

// Important!
// if you change 'SectionName' type, you need to change checker 'checkSectionNameType' in
// 'linguando/app/bin/devops/extract-typescript-translations.js`
// so translates extractor continues to avoid section name translations
export type SectionName = 'english' | 'german' | 'spanish' | 'french' | 'italian';
export type SectionId = number;
export interface Section {
    id: SectionId;
    name: SectionName;
}

export type SectionCurricula = SectionCurriculaQuery['sectionCurricula'];
export type SectionCurriculum = FirstItem<SectionCurricula>;

export type Curriculum = SectionCurriculum['curriculum'];
export type CurriculumId = Curriculum['id'];
export type CurriculumName = string;

export const SectionFactory = createRecordFactory<Section>({
    id: undefined,
    name: undefined,
});

export type SectionCurriculumSlug = string;
