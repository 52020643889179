import { colorsPalette } from './colorsPalette';

export const linkStyles = {
    root: {
        fontWeight: 600,
        textDecorationColor: 'currentcolor',
        overflowWrap: 'break-word',
    },
} as const;

export const linkPrimaryStyles = {
    root: {
        color: colorsPalette.primarySolid[100],
    },
    hover: {
        color: colorsPalette.primaryCustom.dark,
    },
} as const;

export const translatedLinkStyles = {
    ...linkStyles.root,
    ...linkPrimaryStyles.root,
    '&:hover': {
        ...linkPrimaryStyles.hover,
    },
};
