import { Suspense as ReactSuspense } from 'react';
import LinearProgress from './LinearProgress';
import Loader from './Loader';
import type { PropsWithChildren } from 'react';

interface Props {
    variant?: 'circular' | 'linear';
}

const fallbackVariants = {
    circular: <Loader minHeight={40} />,
    linear: <LinearProgress variant="indeterminate" />,
};

const Suspense = ({ children, variant }: PropsWithChildren<Props>) => (
    <ReactSuspense fallback={variant ? fallbackVariants[variant] : null}>{children}</ReactSuspense>
);

export default Suspense;
