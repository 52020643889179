import { forwardRef } from 'react';
import MuiButton, { type ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { type Path, RouterLink } from '@lingoda/router';
import { ThemeProvider, styled, themeInverted } from '../theme';
import ButtonLabel from './ButtonLabel';

export interface ButtonProps extends Omit<MuiButtonProps, 'href'> {
    href?: Path;
    target?: HTMLAnchorElement['target'];
    rel?: HTMLAnchorElement['rel'];
    loading?: boolean;
    inverted?: boolean;
    labelFullWidth?: boolean;
    component?: React.ElementType;
}

const StyledButton = styled(MuiButton, {
    shouldForwardProp: (prop) => prop !== 'loading',
})<ButtonProps>(({ loading }) => ({
    '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        opacity: loading ? 0 : 1,
    },
}));

export const Button = forwardRef<HTMLButtonElement, React.PropsWithChildren<ButtonProps>>(
    ({ href, disabled, loading, children, inverted, labelFullWidth, ...props }, ref) => {
        const button = (
            <StyledButton
                disabled={disabled || loading}
                loading={loading}
                ref={ref}
                // In case url (href) is provided, we use Link component to route it
                {...(href && { to: href, component: RouterLink })}
                disableElevation
                {...props}
            >
                <ButtonLabel loading={loading} size={props.size} fullWidth={labelFullWidth}>
                    {children}
                </ButtonLabel>
            </StyledButton>
        );

        if (inverted) {
            return <ThemeProvider theme={themeInverted}>{button}</ThemeProvider>;
        }

        return button;
    },
);

Button.displayName = 'Button';

export { type ButtonBaseActions } from '@mui/material/ButtonBase';
export { type TouchRippleActions } from '@mui/material/ButtonBase/TouchRipple';
