import { type PropsWithChildren } from 'react';
import { Box } from '../layout/Box';
import CircularProgress from '../feedback/CircularProgress';
import { Fade } from '../transitions';
import { type ButtonProps } from './Button';

interface Props extends Pick<ButtonProps, 'size' | 'children'> {
    loading?: boolean;
    fullWidth?: boolean;
}

const ButtonLabel = ({ loading, size, fullWidth, children }: PropsWithChildren<Props>) => {
    const loaderSize = size === 'small' ? 14 : 21;

    return (
        <>
            {loading && (
                <Fade in>
                    <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aria-hidden
                    >
                        <CircularProgress color="inherit" size={loaderSize} />
                    </Box>
                </Fade>
            )}
            <Fade in={!loading} appear={false}>
                <Box
                    display="inherit"
                    alignItems="inherit"
                    justifyContent="inherit"
                    overflow="inherit"
                    textOverflow="inherit"
                    width={fullWidth ? '100%' : 'auto'}
                >
                    {children}
                </Box>
            </Fade>
        </>
    );
};

export default ButtonLabel;
