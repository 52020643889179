import {
    resetUserAnalyticsInfo,
    setUserAnalyticsInfo,
    updateGtmUserData,
} from '@lingoda/analytics';
import {
    type UserResponse,
    changePassword as changePasswordApi,
    createStudent as createStudentApi,
    getMe,
    requestPasswordReset as requestPasswordResetApi,
    updateForgottenPassword as updateForgottenPasswordApi,
    updateLocale as updateLocaleApi,
    updatePassword as updatePasswordApi,
    updatePreferences as updatePreferencesApi,
    updateUser as updateUserApi,
    updateUserProfile,
    updateUserTimezone,
} from '@lingoda/api';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { clearUserFeatures } from '@lingoda/feature-flags';
import { type ErrorResponse } from '@lingoda/http';
import { setMonitorUser } from '@lingoda/monitor';
import {
    buildFormErrors,
    getPlatform,
    isFormError,
    platform,
    setSessionValue,
} from '@lingoda/utils';
import {
    changePassword,
    createStudent,
    fetchMe,
    logout,
    removeCurrentUser,
    removeUser,
    removeUserData,
    requestMyData,
    requestPasswordReset,
    serverToState,
    updateForgottenPassword,
    updateNotifications,
    updatePassword,
    updateProfile,
    updateSettings,
    updateTimezone,
    updateUser,
    updateVisitorTimezone,
} from '../actions';
import {
    authSelector,
    isAuthStatusUnknownSelector,
    userIdSelector,
    userSelector,
} from '../selectors';
import { sendFinishedUserRegistrationEvent } from '../utils';
import { resetRefreshTokenMechanism } from '../token';
import initLoginSideEffect from './login';

const analyticsPostRegistration = (userId: number, userEmail: string) => {
    void sendFinishedUserRegistrationEvent(userId, userEmail);
};

export default () => {
    initLoginSideEffect();

    addTrackerCallback(updatePassword, (action) => updatePasswordApi(action.payload));

    let fetched = false;
    addCallback(requestMyData, (_action, store) => {
        if (fetched) {
            // We already fetched once. It's enough
            return;
        }

        if (!isAuthStatusUnknownSelector(store.getState())) {
            // User status is known so no need to fetch again
            return;
        }

        fetched = true;
        store.dispatch(fetchMe());
    });

    addTrackerCallback(requestPasswordReset, ({ payload }) =>
        requestPasswordResetApi(payload).catch((response: ErrorResponse) =>
            Promise.reject(isFormError(response) ? buildFormErrors(response.messages) : {}),
        ),
    );

    addTrackerCallback(updateForgottenPassword, ({ payload }) =>
        updateForgottenPasswordApi(payload.token, payload.data).catch((response: ErrorResponse) =>
            Promise.reject(isFormError(response) ? buildFormErrors(response.messages) : {}),
        ),
    );

    addTrackerCallback(updateTimezone, ({ payload }) => updateUserTimezone({ timezone: payload }));

    addTrackerCallback(createStudent, async (action, store) => {
        try {
            const response: UserResponse = await createStudentApi(action.payload);
            // await fetchToken({ password: action.payload.password, username: action.payload.email });
            store.dispatch(serverToState(response));
            const userId = response.data.id.toString();
            const userSection = response?.included?.sections?.[0]?.name as string | undefined;
            const userModule = response?.included?.modules?.[0]?.name;
            setUserAnalyticsInfo({ userId, userModule, userSection });
            setMonitorUser({ id: userId });
            analyticsPostRegistration(response.data.id, response.data.email);

            return response;
        } catch (error) {
            if (isFormError(error)) {
                return Promise.reject(buildFormErrors(error.messages));
            }
        }
    });

    addTrackerCallback(fetchMe, () => getMe());

    addCallback(fetchMe.success, (action, store) => {
        const result = action.payload.result as UserResponse;
        const userId = result.data.id.toString();
        const userSection = result?.included?.sections?.[0]?.name as string | undefined;
        const userModule = result?.included?.modules?.[0]?.name;

        setUserAnalyticsInfo({ userId, userModule, userSection });
        setMonitorUser({ id: userId });

        store.dispatch(serverToState(result));
        if (getPlatform() === platform.web) {
            store.dispatch(updateGtmUserData());
        }
    });

    addCallback(logout, () => {
        resetUserAnalyticsInfo();
        setMonitorUser(null);
        resetRefreshTokenMechanism();
        if (getPlatform() === platform.web) {
            clearUserFeatures();
        }
        const isSkippedRedirect = 'true';
        setSessionValue('isSkippedRedirect', isSkippedRedirect);
    });

    addCallback(removeUser, ({ payload }, store) => {
        store.dispatch(removeCurrentUser());
        store.dispatch(removeUserData(payload));
    });

    addCallback(updateTimezone.success, ({ payload }, store) => {
        const userId = userIdSelector(store.getState());
        if (!userId) {
            return;
        }

        const auth = authSelector(store.getState());

        if (auth?.userId) {
            store.dispatch(updateUser({ id: auth.userId, timezone: payload.payload }));
            store.dispatch(updateVisitorTimezone(payload.payload));
        }
    });

    addTrackerCallback(updateProfile, (action, store) => {
        const { userId, changes } = action.payload;

        if (!userId) {
            return Promise.reject();
        }

        const payload = {
            newPhoto: {},
            ...changes,
        };

        return updateUserProfile(payload).then((response) => {
            store.dispatch(
                updateUser({
                    ...changes,
                    firstName: response.data.firstName,
                    photo: response.data.photo,
                    id: userId,
                }),
            );

            return response;
        });
    });

    addTrackerCallback(changePassword, async (action) => {
        const { studentId, passwordData } = action.payload;

        if (!studentId) {
            return;
        }

        try {
            await changePasswordApi(studentId, passwordData);
        } catch (error) {
            return Promise.reject(error);
        }
    });

    addTrackerCallback(updateSettings, async (action, store) => {
        const user = userSelector(store.getState());

        const {
            studentId,
            changes: { timezone, locale, ...preferences },
        } = action.payload;

        if (!user || !studentId) {
            return;
        }

        try {
            await updatePreferencesApi(studentId, preferences);
            if (locale) {
                await updateLocaleApi({ locale });
            }

            return await updateUserApi(user.id, { timezone });
        } catch (error) {
            return Promise.reject(error);
        }
    });

    addTrackerCallback(updateNotifications, async (action, store) => {
        const user = userSelector(store.getState());
        const {
            studentId,
            changes: { ...preferences },
        } = action.payload;

        if (!user || !studentId) {
            return;
        }

        try {
            return await updatePreferencesApi(studentId, preferences);
        } catch (error) {
            return Promise.reject(error);
        }
    });
};
