import ga from './ga';

export interface Tracker {
    get: (key: string) => string;
}

let tracker: Tracker;

export default (key: string) =>
    new Promise<string>((resolve, reject) => {
        const timeout = setTimeout(() => {
            resolve('');
        }, 2500);

        if (tracker) {
            clearTimeout(timeout);
            resolve(tracker.get(key));
        }

        ga((gaTracker: Tracker) => {
            clearTimeout(timeout);
            tracker = gaTracker;
            resolve(tracker?.get(key));
        }).catch(reject);
    });
