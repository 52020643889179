import { config } from '@lingoda/config';
import { getHost } from './getHost';

const generateSubdomain = (subdomain: string) =>
    [subdomain, ...getHost().split('.').slice(1)].join('.');

export const getMarketingDomain = () => generateSubdomain('www');

export const getTeacherDomain = () => config.domains?.en_TEACHER || generateSubdomain('teacher');

export const getClassroomDomain = () => {
    const isInDev = getHost().split('.').pop() === 'wip';
    if (isInDev) {
        return generateSubdomain('classroom');
    }

    return config.domains?.CLASSROOM || generateSubdomain('classroom');
};

export const getStudentDomain = () => {
    const isInDev = getHost().split('.').pop() === 'wip';
    if (isInDev) {
        return generateSubdomain('learn');
    }

    return config.domains?.STUDENT || generateSubdomain('learn');
};
