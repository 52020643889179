import resolvePath from './resolvePath';
import type { UrlProvider } from './types';
import type { RouterPathFunction } from './utils';

export default (provider: UrlProvider) =>
    <T extends RouterPathFunction<never>>(pathFn: T) => {
        type Result = T extends RouterPathFunction<infer Params> ? Params : never;
        const newPathFn: RouterPathFunction<Result> = (...params) => ({
            provider,
            href: resolvePath((pathFn as unknown as RouterPathFunction<Result>)(...params)),
        });
        newPathFn.raw = pathFn.raw;

        return newPathFn;
    };
