export interface FormMessages {
    [key: string]: string;
}

export interface ErrorMessage {
    message: string;
    type: 'error' | 'warning' | 'info' | 'success';
}
export interface ErrorMessages {
    [key: string]: ErrorMessage[];
}

export interface FormError {
    messages: ErrorMessages;
    message: ErrorMessage;
}

export const isFormMessages = (errors: unknown): errors is FormMessages =>
    typeof errors === 'object';

export const isFormError = (error: unknown): error is FormError =>
    typeof (error as FormError)?.messages === 'object';

export const buildFormErrors = (messages: ErrorMessages) => {
    const formMessages: FormMessages = {};
    if (messages) {
        for (const i in messages) {
            if (!messages.hasOwnProperty(i)) {
                continue;
            }
            const messageRecord = messages[i][0];
            if (messageRecord) {
                let messageKey = i.split('.').pop();
                if (messageKey && messageKey[0] === '_') {
                    messageKey = messageKey.slice(1);
                }

                formMessages[messageKey || 'global'] = messageRecord.message;
            }
        }
    }

    return formMessages;
};
