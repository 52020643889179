import { Box, Button } from '@lingoda/ui';
import { trans } from '@lingoda/i18n';
import { accountPath } from '@lingoda/router';
import WaveSvg from './assets/wave.svg?component';
import CharactersSvg from './assets/characters.svg?component';
import HandleSvg from './assets/handle.svg?component';
import { DialogBubble, WaveWrapper, Wrapper } from './LayoutWrappers';
import type { ErrorLayoutProps } from './LayoutWrappers';

export const AuthenticatedLayout = ({ errorType }: ErrorLayoutProps) => (
    <Wrapper>
        <WaveWrapper top={{ xs: '-60%', md: '-20%' }}>
            <WaveSvg />
        </WaveWrapper>
        <Box
            position="absolute"
            top={{ xs: '90px', md: '180px' }}
            sx={{ fontWeight: 700, fontSize: { xs: '182px', md: '350px' }, color: 'white' }}
        >
            {errorType}
        </Box>
        <Box
            position="absolute"
            top={{ xs: '-60px', md: '70px' }}
            width={{ xs: '450px', md: '785px' }}
            sx={{ transform: { xs: 'scale(0.8) translate(7%, 0)', sm: 'none' } }}
        >
            <CharactersSvg width="100%" />
            <Box
                sx={{
                    transform: { xs: 'rotate(115deg)', md: 'rotate(115deg)', lg: 'rotate(150deg)' },
                    position: 'absolute',
                    left: { xs: '28%', md: '39%', lg: '39%' },
                    top: { xs: '77%', md: '102%', lg: '66%' },
                }}
            >
                <HandleSvg />
            </Box>
            <DialogBubble
                sx={{
                    transform: 'translate(-100%, 0)',
                    left: { xs: '40%', md: '56%', lg: '40%' },
                    top: { xs: '81%', md: '106%', lg: '60%' },
                }}
            >
                {errorType === 404
                    ? trans('notFound-page-not-exist', {}, 'public-common')
                    : trans('notFound-mistake', {}, 'public-common')}
            </DialogBubble>
            <Box
                sx={{
                    transform: { xs: 'rotate(65deg)', md: 'rotate(65deg)', lg: 'rotate(0deg)' },
                    position: 'absolute',
                    left: { xs: '73%', md: '72%', lg: '74%' },
                    top: { xs: '78%', md: '102%', lg: '79%' },
                }}
            >
                <HandleSvg />
            </Box>
            <DialogBubble
                sx={{
                    left: { xs: '58%', md: '72%', lg: '78%' },
                    top: { xs: '81%', md: '106%', lg: '74%' },
                }}
            >
                {errorType === 404
                    ? trans('notFound-mygoodness', {}, 'public-common')
                    : trans('notFound-happens', {}, 'public-common')}
            </DialogBubble>
        </Box>
        <Box position="absolute" top={{ xs: '430px', md: '725px', lg: '625px' }}>
            <Button color="primary" variant="contained" href={accountPath()}>
                {trans('back-to-homepage', {}, 'public-common')}
            </Button>
        </Box>
    </Wrapper>
);
