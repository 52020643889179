export const IGNORED_ERRORS: {
    networkErrors: {
        [statusCode: number]: object[];
    };
    default: object[];
} = {
    networkErrors: {
        400: [
            {
                extensions: {
                    field: 'couponCode',
                },
            },
            {
                extensions: {
                    field: 'productVariant',
                    category: 'Validate',
                },
            },
        ],
    },
    default: [
        {
            message: 'You need to be logged to access this field',
        },
    ],
};
