import { type AcceptedLang, getLang } from '@lingoda/env';
import urls from './urlsAll';

interface Route {
    path: string;
    locale?: AcceptedLang;
    schema?: string;
}

export const getRoute = (name: keyof typeof urls, locale: AcceptedLang = getLang()): Route => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let route = urls[name] as any;
    if (route.local && route.local[locale]) {
        route = { locale, ...route, ...route.local[locale] };
    }

    return route;
};
