import { type CookieAttributes, set } from 'js-cookie';
import { getTopLevelDomain } from '@lingoda/urls';

export { get as getCookieValue, remove as removeCookieValue } from 'js-cookie';

export const setCookieValue = (name: string, value: string, options?: CookieAttributes) => {
    const domain = getTopLevelDomain();
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1, expires.getMonth(), expires.getDate());
    set(name, value, { domain, expires, ...options });
};
