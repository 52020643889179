import type { CreateStudentUserData } from '@lingoda/api';
import { createAction, makeActionTrackable } from '@lingoda/core';

export { default as removeCurrentUser } from './removeCurrentUser';
export { default as removeUser } from './removeUser';
export { default as requestMyData } from './requestMyData';
export { default as setCurrentUser } from './setCurrentUser';
export { default as updatePassword } from './updatePassword';
export { default as login } from './login';
export { default as logout } from './logout';
export { default as sendToken } from './sendToken';
export { default as requestPasswordReset } from './requestPasswordReset';
export { default as requestForgotPassword } from './requestForgotPassword';
export { default as submitForgotPassword } from './submitForgotPassword';
export { default as setStatus } from './setStatus';
export { default as updateForgottenPassword } from './updateForgottenPassword';
export { default as updateTimezone } from './updateTimezone';
export { default as updateSettings } from './updateSettings';
export { default as updateProfile } from './updateProfile';
export { default as changePassword } from './changePassword';
export { default as fetchMe } from './fetchMe';
export { default as updateNotifications } from './updateNotifications';
export * from './usersActions';

export const createStudent = makeActionTrackable(
    createAction<CreateStudentUserData>('user/CREATE_STUDENT'),
);
