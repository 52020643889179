import { httpGet } from '@lingoda/http';
import { buildUrl, getUrl } from '@lingoda/urls';
import type { LearningUnitSchema, LessonSchema, ModuleSchema } from './common';

export interface LearningUnitsResponse {
    data: LearningUnitSchema[];
    included?: {
        lessons: LessonSchema[];
        modules: ModuleSchema[];
    };
}

export const getLearningUnits = (
    studentId: number,
    moduleIds: number[],
): Promise<LearningUnitsResponse> =>
    httpGet(
        buildUrl(getUrl('api_students_learningunits_index', { student_id: studentId }), {
            query: { moduleIds },
        }),
    );
