import { type ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { createDate } from '@lingoda/dates';
import {
    type AppointmentsListQueryVariables,
    mergeTimelineHashList,
    readTimelineHashList,
} from '@lingoda/graphql';
import type { FieldPolicy, Reference } from '@apollo/client';

export const appointmentsList: FieldPolicy = {
    merge(existing, incoming, { args, readField }) {
        const existingHash: Record<string, Reference> = existing || {};
        const incomingList = incoming as Reference[];
        const getItemId = (item: Reference) => readField('id', item) as string;

        return mergeTimelineHashList(
            existingHash,
            incomingList,
            args as AppointmentsListQueryVariables,
            { getItemId },
        );
    },
    read(existingHash, { args, readField }) {
        if (!existingHash) {
            return undefined;
        }

        const getTimestamp = getStartTimestamp(readField);

        return readTimelineHashList(
            existingHash as Record<string, Reference>,
            args as AppointmentsListQueryVariables,
            { getTimestamp },
        );
    },
};

const getStartTimestamp = (readField: ReadFieldFunction) => (appointment: Reference) => {
    return createDate(readField('startDate', readField('class', appointment))).getTime();
};
