import { httpPatch } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type {
    CurriculumSchema,
    ModuleSchema,
    SectionSchema,
    StudentPreferencesSchema,
    StudentSchema,
} from './common';

type Changes = {
    firstName?: string;
    lastName?: string;
    timezone?: string;
    preferences?: StudentPreferencesSchema;
    sectionId?: number;
    module?: number;
    curriculumId?: number;
    onboarded?: boolean;
};

export interface StudentResponse {
    data: StudentSchema;
    included?: {
        sections: SectionSchema[];
        curricula: CurriculumSchema[];
        modules: ModuleSchema[];
    };
}

type ChangePasswordData = {
    oldPassword: string;
    password: string;
};

export const updateStudent = (id: number, changes: Changes): Promise<StudentResponse> =>
    httpPatch(getUrl('api_students_update', { id }), changes);

export const changePassword = (id: number, passwordData: ChangePasswordData): Promise<undefined> =>
    httpPatch(getUrl('api_students_update_password', { id }), passwordData);

export const updatePreferences = (
    id: number,
    preferences: StudentPreferencesSchema,
): Promise<StudentResponse> =>
    httpPatch(getUrl('api_students_update_preferences', { id }), { preferences });
