import { getApp } from '@lingoda/env';
import { isAbsolute } from '@lingoda/urls';
import { UrlProvider } from './types';
import type { Path } from './types';

export default (path: Path) =>
    typeof path === 'string'
        ? // Force self if path is regular string (usually means react route) but we're in legacy
          isAbsolute(path)
        : // Otherwise check if provider is redirect (always means target self)
          path.provider === UrlProvider.External ||
          // Or it's not the right app for the provider
          (path.provider === UrlProvider.Student && getApp() !== 'student');
