import { createSelector } from 'reselect';
import { AuthStatus } from '../models';
import authStatusSelector from './status';

export default createSelector(authStatusSelector, (authStatus) => {
    if (authStatus === AuthStatus.Unknown) {
        return undefined;
    }

    return authStatus === AuthStatus.Authenticated;
});
