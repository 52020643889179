import { type FieldPolicy, type Reference } from '@apollo/client';
import { type ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { compareAsc, createDate, isSameOrAfter } from '@lingoda/dates';
import { type BookingSearchResultsQueryVariables } from '@lingoda/graphql';

export const classes: FieldPolicy<ExistingShape, IncomingShape> = {
    keyArgs: (args) => {
        if (!args || !hasBookingFilter(args)) {
            return;
        }
        const { bookingFilter } = args;
        const { dates, ...filterWithoutDates } = bookingFilter;

        if (['trial', 'multiDate', 'exactDateFiltered'].includes(bookingFilter.slotsType)) {
            return JSON.stringify(args.bookingFilter);
        }

        return JSON.stringify(filterWithoutDates);
    },
    merge: (existing = {}, incoming, { cache, readField }) => {
        const merged = { ...existing };

        const generatedItems = Object.values(existing).reduce(
            (acc, item) => {
                const isExisting = readField<boolean>('isExisting', item);
                if (!isExisting) {
                    acc[getGeneratedItemHash(item, readField)] = true;
                }

                return acc;
            },
            {} as Record<string, true>,
        );

        incoming.forEach((item: Reference) => {
            const itemId = cache.identify(item) as string;
            const isExisting = readField<boolean>('isExisting', item);

            if (isExisting) {
                merged[itemId] = item;

                return;
            }

            const generatedItemHashKey = getGeneratedItemHash(item, readField);
            const existsAlreadyInCache = generatedItems[generatedItemHashKey];

            if (!existsAlreadyInCache) {
                merged[itemId] = item;
            }

            generatedItems[generatedItemHashKey] = true;
        });

        return merged;
    },
    read: (existing, { readField }) => {
        if (!existing) {
            return;
        }

        const sortedItems = Object.values(existing);
        sortedItems.sort((a, b) =>
            compareAsc(
                createDate(readField('startDate', a)),
                createDate(readField('startDate', b)),
            ),
        );
        const actualItems = filterItemsAfterCurrentTime(sortedItems, readField);

        return actualItems;
    },
};

type ExistingShape = Record<string, Reference>;
type IncomingShape = Reference[];

const hasBookingFilter = (
    args: Record<string, unknown>,
): args is BookingSearchResultsQueryVariables => Boolean(args.bookingFilter);

const filterItemsAfterCurrentTime = (sortedItems: Reference[], readField: ReadFieldFunction) => {
    const now = createDate();
    const { result } = sortedItems.reduce(
        (acc, item) => {
            if (acc.skipCheck) {
                acc.result.push(item);

                return acc;
            }

            const startDate = createDate(readField('startDate', item));
            if (isSameOrAfter(startDate, now)) {
                acc.result.push(item);
                acc.skipCheck = true;
            }

            return acc;
        },
        { result: [] as Reference[], skipCheck: false },
    );

    return result;
};

const getGeneratedItemHash = (item: Reference, readField: ReadFieldFunction) => {
    const startDate = readField<string>('startDate', item) || '';
    const lesson = readField<Reference>('lesson', item);
    const lessonId = readField<number>('id', lesson) || 0;

    return `${startDate}-${lessonId}`;
};
