import kebabCase from 'lodash/kebabCase';
import { isCEFR } from '@lingoda/sections';
import type { Reference, TypePolicy } from '@apollo/client';

const sectionCurriculumPolicy: TypePolicy = {
    fields: {
        slug: (_, { readField }) => {
            const sectionObj = readField<Reference>('section');
            const sectionName = readField('name', sectionObj);

            const curriculumObj = readField<Reference>('curriculum');
            const curriculumName = readField<string>('name', curriculumObj) || '';

            return isCEFR(curriculumName) ? sectionName : kebabCase(curriculumName);
        },
    },
};

export default sectionCurriculumPolicy;
