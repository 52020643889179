import { createDate, diffInMinutes } from '@lingoda/dates';
import type { TypePolicy } from '@apollo/client/cache/inmemory/policies';

export const Klass: TypePolicy = {
    keyFields: ['uid'],
    fields: {
        duration: (_, { readField }) => {
            const startDate = readField<string>('startDate');
            const endDate = readField<string>('endDate');

            return diffInMinutes(createDate(endDate), createDate(startDate));
        },
    },
};
