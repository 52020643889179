type AllowUndefined<T> = {
    [P in keyof T]: T[P] | undefined;
};

export default <T>(defaults: AllowUndefined<T>) =>
    (record: Partial<T>): T =>
        ({
            ...defaults,
            ...record,
        }) as T;
