import { type ErrorResponse, onError } from '@apollo/client/link/error';
import isMatch from 'lodash/isMatch';
import { type ErrorContext, type ErrorContexts, captureError } from '@lingoda/monitor';
import { IGNORED_ERRORS } from '../ignoredErrors';
import type { GraphQLError } from 'graphql';
import type { NetworkError } from '@apollo/client/errors';
import type { ServerError } from '@apollo/client/link/utils';
import type { ServerParseError } from '@apollo/client/link/http';

interface ServerGqlError extends ServerError {
    result: { errors?: GraphQLError[] } | string;
}

type ServerErrorTuple = [string, ServerErrorDetails[]];

interface ServerErrorDetails
    extends Pick<
        GraphQLError,
        'path' | 'extensions' | 'positions' | 'locations' | 'nodes' | 'source'
    > {
    classUid?: string;
}

interface ExtraInfo extends ErrorContexts {
    operation: ErrorContext & { operationName: string };
}

interface Params {
    onUnauthorized?: () => void;
}

export const getErrorLink = ({ onUnauthorized }: Params = {}) => {
    const errorHandler: Parameters<typeof onError>[0] = (error) => {
        if (shouldUnauthorize(error)) {
            return onUnauthorized?.();
        }

        const {
            graphQLErrors = [],
            networkError,
            operation: { operationName, variables, extensions },
        } = error;

        const extraInfo: ExtraInfo = {
            operation: {
                operationName,
                variables: stringifyProps(variables),
                extensions: stringifyProps(extensions),
            },
        };

        if (networkError) {
            if (isNetworkErrorIgnored(networkError)) {
                return;
            }

            return captureError(prepareNetworkError(networkError, extraInfo), extraInfo);
        }

        const filteredGqlErrors = graphQLErrors.filter(
            (error) => !isIgnoredError(error, IGNORED_ERRORS.default),
        );

        if (filteredGqlErrors.length) {
            captureError(prepareGraphQLErrors(filteredGqlErrors), extraInfo);
        }
    };

    return onError(errorHandler);
};

const isNetworkErrorIgnored = (error: NetworkError) => {
    if (!error) {
        return true;
    }

    if (isServerError(error)) {
        if (error.statusCode == 401 || error.statusCode === 403) {
            // "You need to be logged to access this field" message
            return true;
        }

        if (typeof error.result === 'string') {
            return false;
        }

        const isIgnoredFunc = (resultError: object) =>
            isIgnoredError(
                resultError,
                IGNORED_ERRORS.networkErrors[error.statusCode] || IGNORED_ERRORS.default,
            );

        if (isGqlErrors(error.result.errors) && error.result.errors.every(isIgnoredFunc)) {
            return true;
        }
    }

    const statusCode = (error as unknown as { status?: number | undefined }).status;
    if (statusCode === 401 || statusCode === 403) {
        // Mute `{"code":401, "message":"An authentication exception occurred.", "status":401}` exception
        return true;
    }

    return false;
};

const prepareNetworkError = (error: NetworkError, extraInfo: ExtraInfo): Error | string => {
    if (isServerGqlError(error)) {
        return prepareServerGqlError(error);
    }

    if (isServerParseError(error)) {
        return prepareServerParseError(error, extraInfo);
    }

    if (error && Object.keys(error).length === 0) {
        const customError = new Error(JSON.stringify(extraInfo));
        customError.name = `Unknown NetworkError (${extraInfo.operation.operationName})`;

        return customError;
    }

    return JSON.stringify(error);
};

const prepareServerGqlError = (error: ServerGqlError) => {
    // Build error name in the following format:
    // "ServerError {status}: {ErrorMessage1} ({path1}). {ErrorMessage2} ({path2})"
    const errors = typeof error.result === 'string' ? [] : error.result.errors || [];
    const errorString = typeof error.result === 'string' ? error.result : '';

    const detailedName = errors.reduce((acc, gqlError) => {
        const paths = (gqlError.path || []).join(', ');
        const pathInfo = paths ? ` (${paths})` : '';

        return `${acc}${gqlError.message}${pathInfo}. `;
    }, `ServerError ${error.statusCode}: ${errorString}`);

    // Build error message in the following format:
    // "[{path: {...}, extensions: {...}, ...], {...}]"
    const extraDetails = errors.reduce((acc, gqlError) => {
        // Skip name, message, stack:
        const { path, extensions, positions, nodes, locations, source } = gqlError;
        acc.push({ path, extensions, positions, nodes, locations, source });

        return acc;
    }, [] as ServerErrorDetails[]);

    const [preparedName, preparedDetails] = maskClassUidServerError('Can not commit to class')([
        detailedName,
        extraDetails,
    ]);

    error.name = preparedName;
    error.message = JSON.stringify(preparedDetails);

    return error;
};

// Mask unique classUid in error name to unify it and group in tracking dashboard
const maskClassUidServerError =
    (errorName: string) =>
    ([name, details]: ServerErrorTuple): ServerErrorTuple => {
        const match = name.match(new RegExp(`${errorName}\\s+(\\w*)`));
        if (!match) {
            return [name, details];
        }

        const classUid = match[1];
        const nextName = name.replace(classUid, '$classUid');
        const nextDetails = details.map((detail) => ({ ...detail, classUid }));

        return [nextName, nextDetails];
    };

const prepareServerParseError = (error: ServerParseError, extraInfo: ExtraInfo) => {
    error.name = `ServerParseError ${error.statusCode}: Unhandled exceptions in ${extraInfo.operation.operationName} operation`;
    error.bodyText = '';

    return error;
};

const prepareGraphQLErrors = (errors: GraphQLError[]): Error | string => {
    const error: GraphQLError | undefined = errors[0];

    if (error) {
        const customError = new Error(error.message);
        customError.name = error.message;

        return customError;
    }

    return JSON.stringify(errors);
};

const isGqlErrors = (errors: unknown): errors is GraphQLError[] => {
    return Array.isArray(errors) && errors.every(isGqlError);
};

const isGqlError = (error: unknown): error is GraphQLError => {
    if (!error) {
        return false;
    }

    const castedError = error as GraphQLError;
    const hasStringMessage = typeof castedError.message === 'string';
    const hasCorrectPath = castedError.path === undefined ? true : Array.isArray(castedError.path);

    return hasStringMessage && hasCorrectPath;
};

const isIgnoredError = (error: object, ignoredErrors: object[]): boolean => {
    return ignoredErrors.some((knownError: object) => isMatch(error, knownError));
};

const isServerGqlError = (error: NetworkError): error is ServerGqlError => {
    return (
        isServerError(error) &&
        (typeof error.result === 'string' || isGqlErrors(error.result.errors))
    );
};

const isServerError = (error: NetworkError): error is ServerError => {
    if (!error) {
        return false;
    }

    const castedError = error as ServerError;

    return castedError.name === 'ServerError' && !!castedError.statusCode;
};

const isServerParseError = (error: NetworkError): error is ServerParseError => {
    if (!error) {
        return false;
    }

    const castedError = error as ServerParseError;

    return castedError.name === 'ServerParseError' && !!castedError.statusCode;
};

const stringifyProps = (object: Record<string, unknown>) => {
    return Object.entries(object).reduce(
        (acc, [key, value]) => {
            acc[key] = JSON.stringify(value);

            return acc;
        },
        {} as Record<string, string>,
    );
};

const shouldUnauthorize = (error: ErrorResponse) => {
    const { graphQLErrors = [], networkError } = error;

    for (const graphQLError of graphQLErrors) {
        switch (graphQLError.extensions?.status) {
            case 403:
            case 401:
                return true;
        }
    }

    if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
        return true;
    }

    return false;
};
