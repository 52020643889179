import { Link, type LinkProps } from 'react-router-dom';
import { forwardRef } from 'react';
import isPath from '../../isPath';
import isReloadRequired from '../../isReloadRequired';
import linkHrefProps from '../../linkHrefProps';
import resolvePath from '../../resolvePath';
import { type Path } from '../../types';

export type To = Path | LinkProps['to'];

export interface RouterLinkProps extends InheritedProps {
    to?: To;
}

type InheritedProps = Omit<LinkProps, 'to'>;

export const RouterLink = forwardRef<HTMLAnchorElement, RouterLinkProps>(
    ({ to, ...passProps }, ref) => {
        if (!to || (isPath(to) && isReloadRequired(to))) {
            return (
                <a ref={ref} {...(to && linkHrefProps(to))} {...passProps}>
                    {passProps.children}
                </a>
            );
        }

        return <Link {...passProps} to={processToProperty(to)} ref={ref} />;
    },
);
RouterLink.displayName = 'RouterLink';

const processToProperty = (to: To): LinkProps['to'] => (isPath(to) ? resolvePath(to) : to);
