import { createBrowserHistory } from 'history';
import { getBaseUri } from '@lingoda/urls';

const history = createBrowserHistory({
    basename: getBaseUri(),
});

export { createBrowserHistory } from 'history';

export default history;
