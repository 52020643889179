import { createRoot } from 'react-dom/client';
import { configureAnalytics, initGtm } from '@lingoda/analytics';
import { startClock } from '@lingoda/clock';
import { initRequestWebProvider } from '@lingoda/http';
import { initErrorTracker } from '@lingoda/monitor';
import { goTo, history, teacherLogoutPath } from '@lingoda/router';
import { assert } from '@lingoda/utils';
import Root from './Root';

initErrorTracker(history);
startClock();

window.addEventListener('load', () => {
    initGtm('teacherFrontendGoogleTagManagerId');
});

void configureAnalytics();

initRequestWebProvider({
    onUnauthorized: () => goTo(teacherLogoutPath()),
});

const mount = document.getElementById('root');
assert(mount, 'React mount element not found');
const root = createRoot(mount);
root.render(<Root history={history} />);
