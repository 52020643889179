import { type ApolloError } from '@apollo/client/errors';
import { getApolloErrorUiMessage } from '@lingoda/graphql';
import { trans } from '@lingoda/i18n';
import { showToast } from './toasts';

export const showApolloErrorToast = (apolloError: ApolloError) => {
    const message = getApolloErrorUiMessage(apolloError) || trans('masked-error', {}, 'errors');

    showToast('error', message);

    return;
};
