import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { type Currency } from '@lingoda/graphql';
import { getNumberOfDigits } from '../getNumberOfDigits';

export const useFormatCurrencyCallback = () => {
    const intl = useIntl();

    return useCallback(
        (amount: number, currency: Currency) => {
            const digits = getNumberOfDigits(amount);

            return intl.formatNumber(amount, {
                style: 'currency',
                currency,
                minimumFractionDigits: digits,
                maximumFractionDigits: digits,
            });
        },
        [intl],
    );
};
