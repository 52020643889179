import curry from 'lodash/curry';

const actionBase = curry((type: string, value: string) => `${type}: ${value}`);

export const addMetadata = (value: GenericObject<unknown>) => ({
    metadata: value,
});

export const actionAppSession = () => 'AppSession';
export const actionClick = actionBase('Click');
export const actionFilter = actionBase('Filter');
export const actionHover = actionBase('Hover');
export const actionLoad = actionBase('Load');
export const actionPageView = actionBase('PageView');
export const actionScroll = actionBase('Scroll');
export const actionScrollComplete = actionBase('100% Scroll');
export const actionSelection = actionBase('Selection');
export const actionView = actionBase('View');
