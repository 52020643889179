export enum Weekdays {
    SUNDAY = 0,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

// NOTE: should be equal to TimeFormat in @lingoda/graphql
export enum TimeFormat {
    Ampm = 'AMPM',
    Iso8601 = 'ISO8601',
}

export enum TimeFormatLegacy {
    Ampm = 'AM/PM',
    Iso8601 = '24-hour',
}

export const dateFormats = ['mm/dd/yy', 'dd/mm/yy', 'yy/mm/dd'];

export type WeekStart = 0 | 2 | 1 | 3 | 4 | 5 | 6;

export const weekStartDays = [Weekdays.SUNDAY, Weekdays.MONDAY];

export interface Recurrence {
    y: number;
    d: number;
    m: number;
}

export interface DateInputObject {
    years?: number;
    year?: number;
    y?: number;

    months?: number;
    month?: number;
    M?: number;

    days?: number;
    day?: number;
    d?: number;

    dates?: number;
    date?: number;
    D?: number;

    hours?: number;
    hour?: number;
    h?: number;

    minutes?: number;
    minute?: number;
    m?: number;

    seconds?: number;
    second?: number;
    s?: number;

    milliseconds?: number;
    millisecond?: number;
    ms?: number;

    quarters?: number;
    quarter?: number;
    Q?: number;

    weeks?: number;
    week?: number;
    w?: number;
}

export type Base = keyof DateInputObject;
