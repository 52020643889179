export type UserAnalyticsInfo = {
    userId?: string;
    userModule?: string;
    userSection?: string;
};

let userAnalyticsInfo: UserAnalyticsInfo = {};

export const setUserAnalyticsInfo = (newInfo: UserAnalyticsInfo = {}, isReset = false) => {
    if (isReset) {
        userAnalyticsInfo = newInfo;
    } else {
        userAnalyticsInfo = {
            ...userAnalyticsInfo,
            ...newInfo,
        };
    }
};

export const getUserAnalyticsInfo = (): UserAnalyticsInfo => userAnalyticsInfo;

export const resetUserAnalyticsInfo = () => setUserAnalyticsInfo({}, true);
