import { type Breakpoint } from '@mui/material';
import { type Resolution, getBreakpointValue } from '../theme';

export const getResolutionBoundaries = (
    resolution: Resolution,
): [from: Breakpoint | null, to: Breakpoint | null] => {
    switch (resolution) {
        case 'desktop-large':
            return ['lg', null];
        case 'desktop':
            return ['md', null];
        case 'tablet':
            return ['sm', 'md'];
        case 'phablet':
            return [null, 'md'];
        case 'phone-large':
            return [null, 'smw'];
        case 'phone': {
            return [null, 'sm'];
        }
        case 'all':
            return ['xs', null];
        case 'none':
            return [null, 'xs'];
        default:
            return ['md', null];
    }
};

export const getResolutionMedia = (resolution: Resolution) => {
    const [from, to] = getResolutionBoundaries(resolution);

    if (!from && to) {
        return `(max-width: ${getBreakpointValue(to)}px)`;
    }

    if (from && !to) {
        return `(min-width: ${getBreakpointValue(from)}px)`;
    }

    if (from && to) {
        return `(min-width: ${getBreakpointValue(from)}px) and (max-width: ${getBreakpointValue(
            to,
        )}px)`;
    }

    return '';
};
