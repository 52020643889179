import { createDate, isSameOrBefore, toUTCString } from '@lingoda/dates';
import { type Callback } from './types';

const scheduledCalls: Record<string, Callback[]> = {};

export const addScheduledCall = (targetDate: Date, callback: Callback) => {
    const now = createDate();
    if (isSameOrBefore(targetDate, now)) {
        return;
    }
    const targetKey = toUTCString(targetDate);
    scheduledCalls[targetKey] = [...(scheduledCalls[targetKey] || []), callback];
};

export const clearScheduledCall = (targetDate: Date, callback: Callback) => {
    const targetKey = toUTCString(targetDate);
    scheduledCalls[targetKey] = (scheduledCalls[targetKey] || []).filter(
        (handler) => handler !== callback,
    );
    if (!scheduledCalls[targetKey].length) {
        delete scheduledCalls[targetKey];
    }
};

export const clearScheduledCalls = () => {
    Object.keys(scheduledCalls).forEach((key) => {
        delete scheduledCalls[key];
    });
};

export const triggerScheduledCalls = (now: Date) => {
    Object.keys(scheduledCalls).forEach((targetKey) => {
        const targetDate = createDate(targetKey);
        if (isSameOrBefore(targetDate, now)) {
            scheduledCalls[targetKey]?.forEach((handler) => handler());
            scheduledCalls[targetKey]?.forEach((handler) =>
                clearScheduledCall(targetDate, handler),
            );
        }
    });
};
