import { Link as MuiLink, type LinkProps as MuiLinkProps, styled } from '@mui/material';
import { type ElementType, forwardRef } from 'react';
import { RouterLink } from '@lingoda/router';
import { type Theme } from '../../theme';

export interface LinkProps extends InheritedProps {
    color?: 'inherit';
    colorHover?: MuiLinkStyledProps['colorHover'];
    component?: ElementType;
    to?: MuiRouterLinkProps['to'];
}

type InheritedProps = Omit<MuiRouterLinkProps, 'color' | 'component' | 'to'>;

type MuiRouterLinkProps = MuiLinkProps<typeof RouterLink>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
    ({ color, colorHover = 'darken', ...passProps }, ref) => {
        return (
            <MuiLinkStyled
                component={RouterLink}
                {...passProps}
                ref={ref}
                color={color}
                colorHover={color === 'inherit' ? colorHover : undefined}
            />
        );
    },
);

Link.displayName = 'Link';

interface MuiLinkStyledProps {
    component?: ElementType;
    colorHover?: 'darken' | 'fade-out' | 'inherit';
}

const MuiLinkStyled = styled(MuiLink, {
    shouldForwardProp: (name) => name !== 'colorHover',
})<MuiLinkStyledProps>(({ theme, colorHover, component, variant = 'inherit' }) => ({
    ...(component === 'button' && variant === 'inherit' && buttonDefaultStylesReset(theme)),
    ...(colorHover && colorHoverMap[colorHover]),
}));

const colorHoverMap = {
    darken: {
        ':hover': {
            filter: 'hue-rotate(-5deg) saturate(120%) brightness(0.55)',
        },
    },
    'fade-out': {
        ':hover': {
            opacity: 0.65,
        },
    },
    inherit: {
        ':hover': {
            color: 'inherit',
        },
    },
} as const;

const buttonDefaultStylesReset = (theme: Theme) => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: 'inherit',
    lineHeight: 'inherit',
});
