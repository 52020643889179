import { setTimeout } from '@lingoda/utils';
import { createDate, getMilliseconds, set } from '@lingoda/dates';
import {
    CLOCK_INTERVALS,
    type ClockInterval,
    ONE_DAY,
    ONE_HOUR,
    ONE_MINUTE,
    ONE_SECOND,
    addClockInterval,
    clearClockInterval,
    clearClockIntervals,
    initIntervalLastCallTimes,
    triggerClockIntervals,
} from './intervalCalls';
import {
    addScheduledCall,
    clearScheduledCall,
    clearScheduledCalls,
    triggerScheduledCalls,
} from './scheduledCalls';

export { type ClockInterval, CLOCK_INTERVALS, ONE_SECOND, ONE_MINUTE, ONE_HOUR, ONE_DAY };

let clockEnabled = false;
let nextTickId = 0;

const tick = () => {
    if (!clockEnabled) {
        return;
    }

    const now = createDate();
    const timeToNextSecond = 1000 - getMilliseconds(now);
    nextTickId = setTimeout(tick, timeToNextSecond);

    triggerClockIntervals(now);
    triggerScheduledCalls(now);
};

// -------------------
// Public API:
// -------------------
export const startClock = () => {
    if (clockEnabled) {
        return;
    }

    clockEnabled = true;

    const now = createDate();
    initIntervalLastCallTimes(now);

    const timeToNextSecond = 1000 - getMilliseconds(now);
    nextTickId = setTimeout(tick, timeToNextSecond);
};

export const stopClock = () => {
    clockEnabled = false;
    clearTimeout(nextTickId);
    clearClockIntervals();
    clearScheduledCalls();
};

export const setClockInterval = (callback: () => void, clockInterval: ClockInterval) => {
    addClockInterval(clockInterval, callback);

    return () => clearClockInterval(clockInterval, callback);
};

export const scheduleClockCallback = (callback: () => void, targetDate: Date) => {
    const roundedTargetDate = set({ milliseconds: 0 }, targetDate);
    addScheduledCall(roundedTargetDate, callback);

    return () => clearScheduledCall(roundedTargetDate, callback);
};
