import uniq from 'lodash/uniq';
import { createDate, startOf } from '@lingoda/dates';
import { assert } from '@lingoda/utils';
import type { FieldPolicy, Reference } from '@apollo/client';

const getDay = (startDate: string | undefined) => {
    assert(startDate, 'Invalid argument');

    // Use timestamp so we can compare as number
    return startOf('day')(createDate(startDate)).getTime();
};

const fieldPolicy: FieldPolicy<Reference[]> = {
    keyArgs: ['filter'],
    merge(existing = [], incoming, { readField }) {
        const incomingDays = uniq(incoming.map((klass) => getDay(readField('startDate', klass))));

        // Replace full days at each fetch
        return [
            ...existing.filter(
                (klass) => !incomingDays.includes(getDay(readField('startDate', klass))),
            ),
            ...incoming,
        ];
    },
};

export default fieldPolicy;
