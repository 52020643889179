import { type ComponentProps } from 'react';
import { FormattedMessage as Source } from 'react-intl';

export interface FormattedMessageProps extends InheritedProps {
    message: string;
}

type InheritedProps = Omit<ComponentProps<typeof Source>, 'id' | 'defaultMessage'>;

export const FormattedMessage = (props: FormattedMessageProps) => {
    const { message, ...passProps } = props;

    return <Source id="no" defaultMessage={props.message} {...passProps} />;
};
