import MuiTypography, {
    type TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { type ElementType, forwardRef } from 'react';
import type { Property } from 'csstype';
import '../../fonts/lingoda/index.css';

const gradientStyles = (gradient: string) => ({
    background: gradient,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

interface OwnProps {
    textTransform?: Property.TextTransform;
    variant?: MuiTypographyProps['variant'];
    component?: ElementType;
    htmlColor?: Property.Color;
    fontFamily?: 'Lingoda Regular' | 'Lingoda Semibold' | 'Lingoda Bold';
    gradient?: string;
}

export type TypographyProps = OwnProps & Omit<MuiTypographyProps, 'variant' | 'fontFamily'>;

export const Typography = forwardRef<HTMLElement, TypographyProps>(
    ({ textTransform, variant = 'body2', htmlColor, gradient, fontFamily, sx, ...props }, ref) => {
        return (
            <MuiTypography
                sx={{
                    textTransform,
                    ...(fontFamily && { fontFamily: `"${fontFamily}", "Open Sans"` }),
                    color: htmlColor ?? 'inherit',
                    ...(gradient && gradientStyles(gradient)),
                    ...sx,
                }}
                variant={variant}
                display="block"
                ref={ref}
                {...props}
            />
        );
    },
);
Typography.displayName = 'Typography';
