import { trans } from '@lingoda/i18n';
import { SupportUrlType, getSupportUrl } from '@lingoda/urls';
import { ErrorCode } from './errorCodes';

const apiErrorCodeToMessage: Record<string, string | undefined> = {
    [ErrorCode.SSO_PRIVATE_EMAIL]: trans(
        'error-apple-signup-permissions',
        { faqUrl: getSupportUrl(SupportUrlType.Apple) },
        'social-auth',
    ),
    [ErrorCode.SSO_UNPROVIDED_EMAIL]: trans(
        'error-facebook-signup-permissions',
        { faqUrl: getSupportUrl(SupportUrlType.Facebook) },
        'social-auth',
    ),
    [ErrorCode.SAME_CARD_FINGERPRINT_TRIAL]: trans(
        'ineligible-free-to-rolling',
        {},
        'public-courses-summary',
    ),
};

export const translateErrorCode = (apiErrorCode: string) => {
    const code = apiErrorCode.toUpperCase();

    return code in apiErrorCodeToMessage ? apiErrorCodeToMessage[code] : undefined;
};
