import { type AppointmentLog, AppointmentStatusBySystem } from '@lingoda/graphql';
import type { TypePolicy } from '@apollo/client/cache/inmemory/policies';

// TODO: move this logic to the BE
export const getAttendanceStatus = ({
    appointmentLog,
}: {
    appointmentLog?: Readonly<AppointmentLog>;
}) => {
    if (appointmentLog) {
        return appointmentLog.isAttended
            ? AppointmentStatusBySystem.Attended
            : AppointmentStatusBySystem.Missed;
    }

    return null;
};

const ActiveAppointment: TypePolicy = {
    fields: {
        attendanceStatus: (_, { readField }) => {
            const appointmentLog = readField<AppointmentLog>('appointmentLog');

            return getAttendanceStatus({ appointmentLog });
        },
    },
};

export default ActiveAppointment;
