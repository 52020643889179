import { type AriaAttributes, type AriaRole } from 'react';
import {
    type AlertColor,
    DangerousHtml,
    type SnackbarProps,
    withSnackbarAdapter,
} from '@lingoda/ui';
import { type ToastCustomProps, type ToastVariant } from '../types';
import { ToastAlert } from './ToastAlert';

declare module 'notistack' {
    interface VariantOverrides {
        default: ToastCustomProps;
        error: ToastCustomProps;
        success: ToastCustomProps;
        warning: ToastCustomProps;
        info: ToastCustomProps;
    }
}

export interface SnackbarToastProps extends ToastCustomProps {
    variant: ToastVariant | 'default';
}

export const SnackbarToast = withSnackbarAdapter<SnackbarToastProps>(
    ({ message, variant, title, icon, dismissable, close, 'data-cy': dataCy }) => {
        const severity = variant === 'default' ? 'error' : variant;
        const isDismissable = dismissable ?? variant !== 'success';

        return (
            <ToastAlert
                title={title}
                severity={severity}
                onClose={isDismissable ? close : undefined}
                icon={icon}
                data-cy={dataCy}
                {...ariaAttributes[severity]}
            >
                <DangerousHtml>{message}</DangerousHtml>
            </ToastAlert>
        );
    },
);

const ariaAttributes: Partial<Record<AlertColor, AriaAttributes | Record<'role', AriaRole>>> = {
    error: {
        'aria-live': 'assertive',
    },
    success: {
        'aria-live': 'polite',
    },
};

export const toastsVariantsMap: Record<
    ToastVariant | 'default',
    React.JSXElementConstructor<SnackbarProps<SnackbarToastProps>>
> = {
    default: SnackbarToast,
    error: SnackbarToast,
    success: SnackbarToast,
    warning: SnackbarToast,
    info: SnackbarToast,
};
