import { captureException, captureMessage as sentryCaptureMessage, withScope } from '@sentry/react';
import { type Context, type Contexts } from '@sentry/types';
import { isSentryTrackingEnabled } from './isSentryTrackingEnabled';

export type ErrorContext = Context;
export type ErrorContexts = Contexts;

export const captureError = (errorInfo: unknown, contexts?: Contexts) => {
    if (!isSentryTrackingEnabled()) {
        return;
    }

    withScope((scope) => {
        scope.setLevel('debug');

        captureException(errorInfo, contexts ? { contexts } : undefined);
    });
};

export const captureMessage = (message: string, contexts?: Contexts) => {
    if (!isSentryTrackingEnabled()) {
        return;
    }

    withScope((scope) => {
        scope.setLevel('debug');

        sentryCaptureMessage(message, contexts ? { contexts } : undefined);
    });
};
