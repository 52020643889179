import { format } from './date-fns';

export * from './common';
export * from './components';
export * from './formatTimeRemaining';
export * from './date-fns';
export { default as getTimezones } from './getTimezones';

// Not based on preference:
export const formatDate = format('yyyy-MM-dd');
export const formatTime = format('HH:mm');
export const formatDateTime = format('yyyy-MM-dd HH:mm:ss');
export const formatIso = format("yyyy-MM-dd'T'HH:mm:ssxxx");
export const formatFullDate = format('dd.MM.yyyy');

// Preference-based:
export const formatTeacherTime = format('p');
export const formatDateTimeHumanFriendly = format('LLLL d yyyy, p');

export const formatDayMonthYear = format('d LLLL yyyy');
export const formatDateDMY = format('d MMM yyyy');
export const formatTimezone = format('O');
