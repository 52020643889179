import { createSelector } from 'reselect';
import { STORE_KEY } from './constants';
import type { Selector } from 'reselect';
import type { Section, SectionName } from './models';

export type SectionsSelectorResult = ReadonlyArray<Section>;

const sectionsStateSelector = (state: GlobalState) => state[STORE_KEY];

export const sectionsSelector = createSelector(
    sectionsStateSelector,
    (sections: Section[]): SectionsSelectorResult => Object.values(sections),
);

export const getSectionSelectorByName = (
    sectionNameSelector: Selector<unknown, SectionName | undefined>,
) =>
    createSelector(sectionNameSelector, sectionsSelector, (sectionName, sections) =>
        sections.find((section) => section.name === sectionName),
    );
