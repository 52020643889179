import { buildQueryString } from './buildQueryString';

export interface QueryParams {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface Params {
    query?: QueryParams;
    hash?: string;
}

export const buildUrl = (url: string, params?: Params) => {
    let requestUrl = url;

    if (params?.query) {
        const queryParamsString = buildQueryString(params.query);
        requestUrl += `?${queryParamsString}`;
    }

    if (params?.hash) {
        requestUrl += `#${params.hash}`;
    }

    return requestUrl;
};
