import { createContext } from 'react';

export interface UiTrackingContextProps {
    sections: string[];
    metadata: GenericObject<unknown>;
}

export const UiTrackingContext = createContext<UiTrackingContextProps>({
    sections: [],
    metadata: {},
});
