import { colorsPalette } from './colorsPalette';
import type { ChipProps } from '@mui/material';
import type { ComponentsVariants } from '@mui/material/styles';

interface VariantStyles {
    labelColor: string;
    borderColor: string;
    backgroundColor: string;
    backgroundColorHover: string;
    borderColorFocus: string;
}

export type ChipColor = Exclude<ChipProps['color'], undefined | 'default'>;

const variantStylesMapping: Record<ChipColor, VariantStyles> = {
    info: {
        labelColor: colorsPalette.blackAlpha[70],
        borderColor: colorsPalette.blackAlpha[16],
        borderColorFocus: colorsPalette.blackAlpha[70],
        backgroundColor: colorsPalette.blackSolid[4],
        backgroundColorHover: colorsPalette.blackSolid[8],
    },
    success: {
        labelColor: colorsPalette.positiveCustom.extradark,
        borderColor: colorsPalette.positiveAlpha[24],
        borderColorFocus: colorsPalette.positiveSolid[100],
        backgroundColor: colorsPalette.positiveSolid[4],
        backgroundColorHover: colorsPalette.positiveSolid[12],
    },
    warning: {
        labelColor: colorsPalette.infoCustom.extradark,
        borderColor: colorsPalette.infoAlpha[24],
        borderColorFocus: colorsPalette.infoSolid[100],
        backgroundColor: colorsPalette.infoSolid[4],
        backgroundColorHover: colorsPalette.infoSolid[12],
    },
    error: {
        labelColor: colorsPalette.negativeCustom.dark,
        borderColor: colorsPalette.negativeAlpha[16],
        borderColorFocus: colorsPalette.negativeSolid[100],
        backgroundColor: colorsPalette.negativeSolid[4],
        backgroundColorHover: colorsPalette.negativeSolid[12],
    },
    secondary: {
        // Known as "Darkblue"
        labelColor: colorsPalette.darkblueSolid[100],
        borderColor: colorsPalette.darkblueAlpha[20],
        borderColorFocus: colorsPalette.darkblueSolid[100],
        backgroundColor: colorsPalette.darkblueSolid[4],
        backgroundColorHover: colorsPalette.darkblueSolid[12],
    },
    primary: {
        // Known as "Primary Blue"
        labelColor: colorsPalette.primarySolid[100],
        borderColor: colorsPalette.primaryAlpha[16],
        borderColorFocus: colorsPalette.primarySolid[100],
        backgroundColor: colorsPalette.primarySolid[4],
        backgroundColorHover: colorsPalette.primarySolid[12],
    },
    promotion: {
        labelColor: colorsPalette.purpleSolid[100],
        borderColor: colorsPalette.purpleAlpha[16],
        borderColorFocus: colorsPalette.purpleSolid[100],
        backgroundColor: colorsPalette.purpleSolid[4],
        backgroundColorHover: colorsPalette.purpleSolid[12],
    },
    pink: {
        labelColor: colorsPalette.pinkCustom.dark,
        borderColor: colorsPalette.pinkAlpha[16],
        borderColorFocus: colorsPalette.pinkSolid[100],
        backgroundColor: colorsPalette.pinkSolid[4],
        backgroundColorHover: colorsPalette.pinkSolid[12],
    },
};

export const getChipVariants = (): ComponentsVariants['MuiChip'] => {
    const variants: ComponentsVariants['MuiChip'] = [];

    for (const [key, styles] of Object.entries(variantStylesMapping)) {
        const color = key as ChipColor;

        variants.push({
            props: { color },
            style: {
                color: styles.labelColor,
                backgroundColor: styles.backgroundColor,
                border: `1px solid ${styles.borderColor}`,
                '&.MuiChip-clickable:hover': {
                    backgroundColor: styles.backgroundColorHover,
                },
                '&:focus, &.Mui-focusVisible': {
                    borderColor: styles.borderColorFocus,
                },
                '&.Mui-focusVisible': {
                    backgroundColor: styles.backgroundColor,
                    '&.MuiChip-clickable:hover': {
                        backgroundColor: styles.backgroundColorHover,
                    },
                },
            },
        });
    }

    return variants;
};
