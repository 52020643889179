import type { AcceptedLang } from '@lingoda/env';
import { trans } from '@lingoda/i18n';
import { type SectionNameTranslation, sectionsTranslationMapping } from '@lingoda/config';
import type {
    CurriculumName,
    SectionCurricula,
    SectionCurriculum,
    SectionCurriculumSlug,
} from './models';

const DEFAULT_CURRICULUM = 'CEFR';

export const isCEFR = (curriculum: string) => curriculum === DEFAULT_CURRICULUM;

export const getSectionName = (sectionCurriculum: SectionCurriculum): SectionName =>
    sectionCurriculum.section.name;

export const getCurriculumName = (sectionCurriculum: SectionCurriculum): CurriculumName =>
    sectionCurriculum.curriculum.name;

export const findSectionCurriculum = (
    sectionCurricula: SectionCurricula,
    sectionName: SectionName,
    curriculumSlug: SectionCurriculumSlug,
): SectionCurriculum | undefined =>
    sectionCurricula.find(
        (sectionCurriculum) =>
            sectionName === getSectionName(sectionCurriculum) &&
            curriculumSlug === sectionCurriculum.slug,
    );

export const getActiveSectionCurriculum = (
    sectionCurricula: SectionCurricula,
    sectionId: number,
    curriculumId: number,
) => {
    return sectionCurricula.find(
        (item) => item.section.id === sectionId && item.curriculum.id === curriculumId,
    );
};

export const localeToSectionMap: Record<AcceptedLang, SectionName | undefined> = {
    en: 'english',
    de: 'german',
    es: 'spanish',
    fr: 'french',
    ru: undefined,
    pl: undefined,
    tr: undefined,
    it: undefined,
    pt_BR: undefined,
};

export const sectionToLocaleMap: Record<SectionName, AcceptedLang> = {
    english: 'en',
    german: 'de',
    spanish: 'es',
    french: 'fr',
    italian: 'it',
};

export const sectionName: Record<string, SectionName> = {
    english: 'english',
    german: 'german',
    spanish: 'spanish',
    french: 'french',
    italian: 'italian',
};

export const sectionNames = Object.values(sectionName);

export const transformSectionName = (section: string): SectionName => {
    return translationToSectionNameMap[section as SectionNameTranslation];
};

const translationToSectionNameMap = Object.entries(sectionsTranslationMapping).reduce(
    (acc, [sectionName, translations]) => {
        Object.values(translations).forEach((translation) => {
            acc[translation] = sectionName as SectionName;
        });

        return acc;
    },
    {} as Record<SectionNameTranslation, SectionName>,
);

export const getTranslatedSection = (sectionCurriculumSlug: string) => {
    switch (sectionCurriculumSlug) {
        case 'english':
            return trans('i-want-to-learn-english', {}, 'student-registration');
        case 'business-english':
            return trans('i-want-to-learn-business-english', {}, 'student-registration');
        case 'german':
            return trans('i-want-to-learn-german', {}, 'student-registration');
        case 'spanish':
            return trans('i-want-to-learn-spanish', {}, 'student-registration');
        case 'french':
            return trans('i-want-to-learn-french', {}, 'student-registration');
        case 'italian':
            return trans('i-want-to-learn-italian', {}, 'student-registration');
        default:
            return '';
    }
};
