import { colorsPalette } from './colorsPalette';
import type { AlertProps } from '@mui/material';
import type { ComponentsVariants } from '@mui/material/styles';

interface VariantStyles {
    backgroundColor: string;
    borderColor: string;
    iconColor: string;
    titleColor: string;
}

type AlertColor = Required<AlertProps>['color'];

const variantStylesMapping: Record<AlertColor, VariantStyles> = {
    error: {
        backgroundColor: colorsPalette.negativeSolid[4],
        borderColor: colorsPalette.negativeAlpha[40],
        iconColor: colorsPalette.negativeSolid[100],
        titleColor: colorsPalette.negativeCustom.dark,
    },
    info: {
        backgroundColor: colorsPalette.blackSolid[3],
        borderColor: colorsPalette.blackAlpha[20],
        iconColor: colorsPalette.blackAlpha[70],
        titleColor: colorsPalette.blackSolid[100],
    },
    success: {
        backgroundColor: colorsPalette.positiveSolid[4],
        borderColor: colorsPalette.positiveAlpha[40],
        iconColor: colorsPalette.positiveAlpha[70],
        titleColor: colorsPalette.positiveCustom.extradark,
    },
    warning: {
        backgroundColor: colorsPalette.infoSolid[4],
        borderColor: colorsPalette.infoAlpha[40],
        iconColor: colorsPalette.infoSolid[100],
        titleColor: colorsPalette.infoCustom.extradark,
    },
    promotion: {
        backgroundColor: colorsPalette.purpleSolid[4],
        borderColor: colorsPalette.purpleAlpha[16],
        iconColor: colorsPalette.purpleSolid[100],
        titleColor: colorsPalette.purpleCustom.dark,
    },
};

export const getAlertVariants = (): ComponentsVariants['MuiAlert'] => {
    const variants: ComponentsVariants['MuiAlert'] = [];

    for (const [key, styles] of Object.entries(variantStylesMapping)) {
        const color = key as AlertColor;

        variants.push({
            props: { color },
            style: {
                backgroundColor: styles.backgroundColor,
                '& .MuiAlert-icon': {
                    color: styles.iconColor,
                },
                '& .MuiAlertTitle-root': {
                    color: styles.titleColor,
                },
            },
        });

        variants.push({
            props: { color, variant: 'outlined' },
            style: {
                borderColor: styles.borderColor,
            },
        });
    }

    return variants;
};
