import { login as loginApi } from '@lingoda/api';
import { addCallback, addTrackerCallback } from '@lingoda/core';
import { buildFormErrors, isFormError } from '@lingoda/utils';
import { fetchMe, login } from '../actions';

export default () => {
    addTrackerCallback(login, async (action) => {
        try {
            return await loginApi(action.payload);
        } catch (response) {
            return Promise.reject(isFormError(response) ? buildFormErrors(response.messages) : {});
        }
    });

    addCallback(login.success, (_, store) => {
        store.dispatch(fetchMe());
    });
};
