import { type AcceptedLang, getLang } from '@lingoda/env';
import { getRoute } from './getRoute';
import { type Params, removePlaceholders, replaceParams } from './replaceParams';
import type { Urls } from './urlsAll';

export const getPath = ((
    name: Parameters<typeof getRoute>[0],
    params: Params = {},
    locale: AcceptedLang = getLang(),
): string => {
    const route = getRoute(name, locale);

    return removePlaceholders(replaceParams(route.path, params));
}) as Urls;
