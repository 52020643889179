import { cancelRequestIds } from '../request';
import { isActionTrackable } from './makeActionTrackable';
import { trackRequest } from './requests';
import type { Action, ActionMeta, BaseActionFunctions } from './createAction';
import type { Middleware, Store } from 'redux';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback<A = any> = (action: A, store: Store) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TrackerCallback<A = any> = (action: A, store: Store) => Promise<unknown>;

const callbacks: { [key: string]: Callback[] } = {};

const requestsToCancel: { [key: string]: boolean } = {};

export function addCallback<T extends BaseActionFunctions<unknown>, A extends ReturnType<T>>(
    type: T | T[],
    callback: (action: A, store: Store) => void,
): void;
export function addCallback<A extends Action<unknown>>(
    type: string,
    callback: ((action: A, store: Store) => void) | ((action: A, store: Store) => Promise<unknown>),
): void;

export function addCallback(type: unknown, callback: Callback) {
    if (Array.isArray(type)) {
        type.forEach((aType) => addCallback(aType, callback));

        return;
    }
    const typeString = typeof type === 'string' ? type : `${type}`;
    if (!callbacks[typeString]) {
        callbacks[typeString] = [];
    }

    callbacks[typeString].push(callback);
}

const cancellableActionPromise = (
    action: ActionMeta<unknown, { id: string }>,
    promise: Promise<unknown>,
) =>
    promise.then((result) => {
        if (requestsToCancel[action.meta.id]) {
            delete requestsToCancel[action.meta.id];

            return Promise.reject();
        } else {
            return Promise.resolve(result);
        }
    });

export function addTrackerCallback<T extends BaseActionFunctions<unknown>, A extends ReturnType<T>>(
    type: T | T[],
    callback: (action: A, store: Store) => Promise<unknown>,
): void;
export function addTrackerCallback<A extends ActionMeta<unknown, M>, M extends { id: string }>(
    type: string,
    callback: (action: A, store: Store) => Promise<unknown>,
): void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addTrackerCallback(type: any, callback: TrackerCallback) {
    if (Array.isArray(type)) {
        type.forEach((item) => addTrackerCallback(item, callback));

        return;
    }

    addCallback(type, (action: ActionMeta<unknown, { id: string }>, store: Store<unknown>) => {
        const promise = cancellableActionPromise(action, callback(action, store));

        if (isActionTrackable(type)) {
            promise.then(
                (result) =>
                    store.dispatch(
                        type.success({ payload: action.payload, meta: action.meta, result }),
                    ),
                (result) =>
                    store.dispatch(
                        type.failure({ payload: action.payload, meta: action.meta, result }),
                    ),
            );
        }

        trackRequest(store, action, promise);
    });
}

// A generic middleware that allows to add callbacks based on event type. Makes simpler when all one
// needs to do is add simple callback on actions. No need to create custom middleware for each case
export const actionCallback: Middleware =
    (store) =>
    (next) =>
    <T extends Action<unknown>>(action: T) => {
        const result = next(action);

        if (action.type === cancelRequestIds.toString()) {
            (action as ReturnType<typeof cancelRequestIds>).payload.forEach((requestId) => {
                requestsToCancel[requestId] = true;
            });
        }

        if (callbacks[action.type]) {
            callbacks[action.type].forEach((callback) => {
                callback(action, store as Store<unknown>);
            });
        }

        return result;
    };
