import { v1 as uuidV1 } from 'uuid';
import { config } from '@lingoda/config';
import { getFeatures } from '@lingoda/feature-flags';
import { getCookieValue, setCookieValue } from '@lingoda/cookie';
import { getStorageValue, memoizePromise } from '@lingoda/utils';
import { getLang } from '@lingoda/env';
import { gaClientId, getTrackingId } from '../ga';
import { loadAnalytics } from './common';
import { getUserAnalyticsInfo } from './userAnalytics';
import type { Event } from './models';

/* eslint-disable no-restricted-globals */
const getRequestParameter = (paramName: string) => location.search.split(`${paramName}=`)[1];
const DISTINCT_ID = 'distinct_id';

const getAuthId = memoizePromise(async () => {
    const Auth = (await import('@aws-amplify/auth')).default;

    return (await Auth.currentCredentials()).identityId;
});

const getAnalyticsData = memoizePromise(async () => ({
    clientId: await gaClientId(),
    trackingId: await getTrackingId(),
}));

const getDistinctId = () => {
    let distinctId = getCookieValue(DISTINCT_ID);
    if (!distinctId) {
        distinctId = uuidV1();
        setCookieValue(DISTINCT_ID, distinctId);
    }

    return distinctId;
};

const getAppData = () => {
    return {
        sr: `${screen.width}x${screen.height}`,
        vp: `${innerWidth}x${innerHeight}`,
        url: location.href,
        locale: getLang(),
        utm_source: getRequestParameter('utm_source'),
        utm_campaign: getRequestParameter('utm_campaign'),
        utm_term: getRequestParameter('utm_term'),
        utm_content: getRequestParameter('utm_content'),
        distinctId: getDistinctId(),
        releaseId: config.releaseId,
    };
};

const addEvent = async (event: Event) => {
    const Analytics = await loadAnalytics();
    const id = uuidV1();
    const identityId = await getAuthId();
    const platformData = { platform: 'web' };
    const { userId, ...userInfo } = getUserAnalyticsInfo();
    const metadata = { ...(event.metadata || {}), ...userInfo, ...platformData };

    let analyticsData = {};
    try {
        analyticsData = await getAnalyticsData();
    } catch {}

    const record = {
        data: {
            ...event,
            ...getAppData(),
            ...analyticsData,
            ...platformData,
            features: getFeatures(),
            metadata,
            userId,
            id,
            identityId,
        },
        streamName: 'ClickEventStream',
        partitionKey: id,
    };

    // for debugging purposes
    if (getStorageValue('displayContextLayout')) {
        console.debug('[debug] kinesis event:');
        console.dir(record);
    }

    return Analytics?.record(record, 'AWSKinesis');
};

export default addEvent;
