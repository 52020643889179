import { httpGet, httpPatch, httpPost } from '@lingoda/http';
import { type ClassType } from '@lingoda/graphql';
import { getUrl } from '@lingoda/urls';
import type { CurriculumId, Section, SectionName } from '@lingoda/sections';
import { type AcceptedLang } from '@lingoda/env';
import type {
    RegisterUserParams,
    StudentCreditSchema,
    StudentInfoSchema,
    StudentModuleSchema,
    StudentSchema,
    UserSchema,
} from './common';

export interface UsersUserSchema extends UserSchema {
    student: StudentSchema;
}

export interface UserResponse {
    data: UsersUserSchema;
    included?: {
        sections?: Section[];
        credits?: StudentCreditSchema[];
        modules?: StudentModuleSchema[];
        studentInfos?: StudentInfoSchema[];
    };
}

type ResetPasswordData = {
    email: string;
};

export const requestPasswordReset = (resetPasswordData: ResetPasswordData) =>
    httpPost(getUrl('api_users_reset_password'), resetPasswordData);

type UpdateForgottenPasswordData = {
    plainPassword: string;
};

export interface ForgottenPasswordSchema {
    redirectUrl: string;
}

export interface ForgottenPasswordResponse {
    data: ForgottenPasswordSchema;
}

export const updateForgottenPassword = (
    token: string,
    updateForgottenPasswordData: UpdateForgottenPasswordData,
): Promise<ForgottenPasswordResponse> =>
    httpPost(getUrl('api_users_update_forgotten_password', { token }), updateForgottenPasswordData);

export const updatePassword = (updatePasswordData: UpdateForgottenPasswordData) =>
    httpPatch(getUrl('api_users_update_password'), updatePasswordData);

type ConsentData = {
    marketing_communication?: boolean;
    privacy_policy: boolean;
};

export type CreateStudentUserData = Partial<Omit<RegisterUserParams, 'curriculumId'>> & {
    consent: ConsentData;
    email: string;
    fullName?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    section: SectionName;
    curriculum?: CurriculumId;
};

export const createStudent = (studentData: CreateStudentUserData): Promise<UserResponse> =>
    httpPost(getUrl('api_users_create'), studentData);

type ModuleData = {
    id: number;
};

type StudentInfoData = {
    birthCity?: string;
    birthCountry?: string;
};

type UserData = {
    timezone?: string;
    module?: ModuleData;
    studentInfo?: StudentInfoData;
    newPhoto?: {
        binaryContent?: string;
    };
};

export const updateUser = (id: number, data: UserData): Promise<UserResponse> =>
    httpPatch(getUrl('api_users_update', { id }), data);

export const getMe = (): Promise<UserResponse> => httpGet(getUrl('api_users_me'));

type ScheduleClassData = {
    sectionName: SectionName;
    moduleId: number;
    lessonId: number;
    classType: ClassType;
    timezone: string;
    classDate: string;
};

export const scheduleAnonymousClass = (data: ScheduleClassData): Promise<void> =>
    httpPost(getUrl('api_users_add_scheduled_class'), data);

type UpdateUserProfileData = {
    fullName?: string;
    email?: string;
    newPhoto?: {
        binaryContent?: string;
    };
    birthCity?: string;
    birthCountry?: string;
};

export const updateUserProfile = (data: UpdateUserProfileData): Promise<UserResponse> =>
    httpPatch(getUrl('api_users_profile'), data);

type UpdateUserTimezoneData = {
    timezone: string;
};

export const updateUserTimezone = (data: UpdateUserTimezoneData): Promise<UserResponse> =>
    httpPatch(getUrl('api_users_timezone'), data);

type UpdateInvoiceAddressData = {
    invoiceAddress: string;
};

export const updateInvoiceAddress = (data: UpdateInvoiceAddressData): Promise<UserResponse> =>
    httpPatch(getUrl('api_users_invoice_address'), data);

type LocaleData = {
    locale: AcceptedLang;
};

export const updateLocale = (data: LocaleData): Promise<unknown> =>
    httpPatch(getUrl('api_users_update_locale'), data);

interface ResendVerificationEmailResponse {
    data: { success: true };
}

export const resendVerificationEmail = (): Promise<ResendVerificationEmailResponse> =>
    httpPost(getUrl('api_users_resend_confirmation'));
