import type { GoogleWindow, Props } from '../common';

type Argument = string | number | Props;

declare const window: GoogleWindow;

let enabled = true;

export const resetEnable = () => (enabled = true);

export default (...args: Argument[]) =>
    new Promise<void>((resolve, reject) => {
        let retries = 10;
        const ga = () => {
            if (!enabled) {
                reject();

                return;
            }

            if (window && window.ga) {
                window.ga(...args);
                resolve();

                return;
            }

            if (retries > 0) {
                retries--;
                setTimeout(ga, 500);
            } else {
                reject('Ga not loaded');
                enabled = false;
            }
        };

        ga();
    });
