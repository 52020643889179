import { InMemoryCache } from '@apollo/client';
import { memoize } from '@lingoda/utils';
import { generated } from '@lingoda/graphql';
import { typePolicies } from './typePolicies';

const getGraphQLCache = memoize(
    () =>
        new InMemoryCache({
            typePolicies,
            possibleTypes: generated.possibleTypes,
        }),
);

export default getGraphQLCache;
