import { type Breakpoint, createTheme } from '@mui/material/styles';

const breakpointsMap: Record<Breakpoint, number> = {
    xs: 0, // extra small, min supported value is 360px
    smn: 480, // small narrow
    sm: 600, // small
    smw: 770, // small wide
    md: 960, // medium
    lg: 1160, // large
    xl: 1920, // extra large
};

/* Material UI v.5 uses different breakpoints, setting back those in v.4 */
export const breakpoints = createTheme({
    breakpoints: {
        values: breakpointsMap,
    },
}).breakpoints;

export const getBreakpointValue = (breakpoint: Breakpoint): number => breakpointsMap[breakpoint];
