import { createSelector } from 'reselect';
import { getTimezone } from '@lingoda/dates';
import { getLang } from '@lingoda/env';
import { currentRouteSectionSelector } from '@lingoda/router';
import { localeToSectionMap, sectionToLocaleMap, sectionsSelector } from '@lingoda/sections';
import { unifyNullable } from '@lingoda/utils';
import { USERS_STORE_KEY } from '../constants';
import authSelector from './state';
import type { User } from '../models';

const usersStateSelector = (state: GlobalState) => state[USERS_STORE_KEY];

export const usersSelector = createSelector(
    usersStateSelector,
    (users: User[]): ReadonlyArray<User> => Object.values(users),
);

export const userSelector = createSelector(authSelector, usersSelector, (auth, users) =>
    auth && users ? users.find((user) => user.id === auth.userId) : undefined,
);

export const firstNameSelector = createSelector(userSelector, (user) => user?.firstName);

export const userIdSelector = createSelector(userSelector, (user) => user?.id);

export const userUidSelector = createSelector(userSelector, (user) => user?.uid);

export const userProfileThumbnailSelector = createSelector(
    userSelector,
    (user) => user && user.photo && user.photo.thumbnail2x,
);

export const userIsSprintRunnerSelector = createSelector(
    userSelector,
    (user) => user?.isSprintRunner,
);

export const userSectionSelector = createSelector(
    sectionsSelector,
    userSelector,
    (sections, user) =>
        user ? sections.find((section) => section.id === user.sectionId) : undefined,
);

export const sectionNameSelector = createSelector(userSectionSelector, (section) =>
    unifyNullable(section?.name),
);

export const sectionLocaleSelector = createSelector(sectionNameSelector, (section) =>
    !section ? undefined : sectionToLocaleMap[section],
);

export const isLocaleMatchSectionSelector = createSelector(
    sectionNameSelector,
    () => localeToSectionMap[getLang()],
    (section, sectionFromLocale) => section?.toString() === sectionFromLocale,
);

export const currentVisitorTimezoneSelector = createSelector(
    userSelector,
    (user) => user?.timezone || getTimezone(),
);

export const cancelledExpiresAtSelector = createSelector(
    userSelector,
    (user) => user?.cancelledExpiresAt,
);

export const userCreatedAtSelector = createSelector(userSelector, (user) => user?.createdAt);

export const currentSectionSelector = createSelector(
    currentRouteSectionSelector,
    sectionNameSelector,
    (currentRouteSection, sectionName) => (currentRouteSection ? currentRouteSection : sectionName),
);

export const isUserEmailVerifiedSelector = createSelector(
    userSelector,
    (user) => user?.isEmailVerified,
);

export const needToShowBookingSurvey = createSelector(
    userSelector,
    (user) => !!user?.showClassBookingExperienceSurvey,
);
