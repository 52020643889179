import studentUrls from './urls';
import classroomUrls from './urlsClassroom';
import type { Urls as StudentUrls } from './urls';
import type { ClassroomUrls } from './urlsClassroom';

export type Urls = StudentUrls & ClassroomUrls;

const urls = { ...studentUrls, ...classroomUrls } as const;

export default urls;
