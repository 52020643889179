import { Helmet } from 'react-helmet-async';
import { trans } from '@lingoda/i18n';
import { Loader } from '@lingoda/ui';
import { isAuthStatusUnauthenticatedSelector, isAuthStatusUnknownSelector } from '@lingoda/auth';
import { isInTeacherDomain } from '@lingoda/urls';
import { useSelector } from '@lingoda/hooks';
import { AuthenticatedLayout } from './AuthenticatedLayout';
import { UnAuthenticatedLayout } from './UnauthenticatedLayout';
import type { ErrorLayoutProps } from './LayoutWrappers';

export const ErrorPageLayout = (props: ErrorLayoutProps) => (
    <>
        <Helmet>
            <title>{trans('page-not-found-title-', {}, 'public-meta')}</title>
            <meta name="robots" content="noindex" />
            <meta name="googlebot" content="noindex" />
            <meta name="description" content={trans('main-description', {}, 'public-meta')} />
            <meta name="keywords" content={trans('main-keywords-', {}, 'public-meta')} />
        </Helmet>
        <Layout {...props} />
    </>
);

const Layout = ({ errorType }: ErrorLayoutProps) => {
    const isUnknown = useSelector(isAuthStatusUnknownSelector);
    const isUnauthenticated = useSelector(isAuthStatusUnauthenticatedSelector);

    // TBE is redirecting to APP since it's angular written, this is just a temporary fix
    if (isInTeacherDomain()) {
        return <AuthenticatedLayout errorType={errorType} />;
    }

    if (isUnknown) {
        return <Loader />;
    }

    return isUnauthenticated ? (
        <UnAuthenticatedLayout errorType={errorType} />
    ) : (
        <AuthenticatedLayout errorType={errorType} />
    );
};
