import { createAction, makeActionTrackable } from '@lingoda/core';

export interface Changes {
    nextClass?: boolean;
    oneClickBooking?: boolean;
    marketingAgreement?: boolean;
    emailCancelledClassConfirmation?: boolean;
    emailBookedClassConfirmation?: boolean;
    emailClassReminder?: boolean;
    emailWeeklyReport?: boolean;
    marketingCommunicationEmail?: boolean;
    marketingCommunicationPhone?: boolean;
    marketingCommunicationWhatsApp?: boolean;
}

export default makeActionTrackable(
    createAction(
        'student/UPDATE_SETTINGS_NOTIFICATIONS',
        (studentId: number | undefined, changes: Changes) => ({
            changes,
            studentId,
        }),
    ),
);
