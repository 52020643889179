import type { Date } from '@lingoda/dates';
import { format } from '@lingoda/dates';
import { httpGet, httpPost } from '@lingoda/http';
import { buildUrl, getUrl } from '@lingoda/urls';
import type {
    AppointmentSchema,
    ClassSchema,
    LearningUnitSchema,
    LessonSchema,
    ModuleSchema,
    StudentSchema,
    TeacherSchema,
} from './common';

interface IncludedResponseAttributes {
    learningUnits?: LearningUnitSchema[];
    lessons?: LessonSchema[];
    modules?: ModuleSchema[];
    teachers?: TeacherSchema[];
    students?: StudentSchema[];
}

interface RootAppointmentSchema extends AppointmentSchema {
    class: ClassSchema;
}

export interface AppointmentsResponse {
    data: RootAppointmentSchema[];
    included: IncludedResponseAttributes;
}

export interface AppointmentResponse {
    data: RootAppointmentSchema;
    included: IncludedResponseAttributes;
}

interface CreateAppointmentResponse {
    data: RootAppointmentSchema;
    included: IncludedResponseAttributes;
}

export interface GetAllByStudentPayload {
    studentId: number;
    before?: Date;
    after?: Date;
    lessonId?: number;
    limit?: number;
}

export const createAppointment = (
    classId: string,
    studentId: string,
    stats: string | undefined,
): Promise<CreateAppointmentResponse> =>
    httpPost(getUrl('api_classes_appointments_create', { uniqueId: classId }), {
        classId,
        studentId,
        stats,
    });

export const getAllAppointmentsByStudent = (
    payload: GetAllByStudentPayload,
): Promise<AppointmentsResponse> => {
    const url = getUrl('api_students_appointments_list', { student_id: payload.studentId });

    if (payload.before || payload.lessonId || payload.after) {
        const query: { before?: string; lesson?: number; after?: string; limit?: number } = {};

        if (payload.before) {
            query.before = format('yyyy-MM-dd+HH:mm:ss', payload.before);
        }

        if (payload.after) {
            query.after = format('yyyy-MM-dd+HH:mm:ss', payload.after);
        }

        if (payload.limit) {
            query.limit = payload.limit;
        }

        if (payload.lessonId) {
            query.lesson = payload.lessonId;
        }

        return httpGet(buildUrl(url, { query }));
    }

    return httpGet(url);
};
