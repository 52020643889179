import { createAction, makeActionTrackable } from '@lingoda/core';

export default makeActionTrackable(
    createAction(
        'auth/CHANGE_PASSWORD',
        (
            studentId: number | undefined,
            passwordData: {
                password: string;
                oldPassword: string;
            },
        ) => ({
            studentId,
            passwordData,
        }),
    ),
);
