export interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export type Argument = string | number | Props;

export interface GoogleAnalytics {
    l: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    q: any[];
    (...args: Argument[]): void;
}

type PaqItem = string | number | (() => void);
type PaqEvent = PaqItem[];

export interface GoogleWindow extends Window {
    ga: GoogleAnalytics | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
    _paq: PaqEvent[];
}

export const HIT_TYPE_EVENT = 'event';

export const EVENT_REGISTRATION_FINISHED = 'Registration Finished';
export const EVENT_PRODUCT_ADDED_TO_CART = 'Product Added To Cart';

export const CATEGORY_LEAD = 'lead';
export const CATEGORY_ADD_TO_CART = 'Add To Cart';

export const ACTION_FINISHED_USER_REGISTRATION = 'Finished User Registration';
export const ACTION_PRODUCT_SELECTED = 'Product Selected';

export const LABEL_PLUTO = 'Pluto';

export const MIN_SESSION_TIME = 10000; // 10 seconds from mix panel
