import { Box } from '@lingoda/ui';
import { colorsPalette, styled } from '@lingoda/ui/theme';

export interface ErrorLayoutProps {
    errorType: 404 | 500;
}

export const Wrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colorsPalette.primarySolid[8],
    height: '600px',
    minHeight: '100dvh',
    [theme.breakpoints.up('md')]: {
        height: '800px',
    },
}));

export const WaveWrapper = styled(Box)(() => ({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
}));

export const DialogBubble = styled(Box)(({ theme }) => ({
    width: 'max-content',
    backgroundColor: 'white',
    color: colorsPalette.darkblueSolid[100],
    textAlign: 'center',
    lineHeight: 1.75,
    fontWeight: 600,
    borderRadius: 75,
    position: 'absolute',

    fontSize: 20,

    padding: theme.spacing(2.5, 4),
    maxWidth: '330px',

    [theme.breakpoints.down('md')]: {
        fontSize: 14,
        padding: theme.spacing(1.5, 2),
        maxWidth: '180px',
    },
}));
