export enum platform {
    reactNative = 'reactNative',
    web = 'web',
}

export const getPlatform = () => {
    if (typeof document !== 'undefined') {
        return platform.web;
    } else if (navigator?.product === 'ReactNative') {
        return platform.reactNative;
    }
};
