import type { SnackbarKey } from '@lingoda/ui';
import { closeSnackbar, enqueueSnackbar, matchResolutionMedia } from '@lingoda/ui';
import type { Toast, ToastVariant } from './types';

export function showToast(toast: ToastVariant, message: string): SnackbarKey;
export function showToast(toast: Toast): SnackbarKey;
export function showToast(toast: unknown, message?: string) {
    let newToast = null;
    if (typeof toast === 'string' && message) {
        newToast = {
            variant: toast as ToastVariant,
            message,
        };
    } else if (toast && typeof (toast as Toast)?.variant === 'string') {
        newToast = toast as Toast;
    } else {
        throw new Error('Invalid notification provided');
    }

    return triggerToast(newToast);
}

export const showToasts = (toasts: Array<Toast>) => {
    toasts.forEach(triggerToast);
};

export const hideToast = (key?: SnackbarKey | null) => {
    if (key) {
        closeSnackbar(key);
    }
};

const triggerToast = (toast: Toast) => {
    const isMobile = matchResolutionMedia('phone');

    // NOTE: call enqueueSnackbar() with 2 args (message, options)
    // to enable proper type mapping of custom props based on 'variant' value
    return enqueueSnackbar(toast.message, {
        ...toast,
        variant: toast.variant,
        persist: toast.variant !== 'success',
        transitionDuration: { enter: 500, exit: 500 },
        autoHideDuration: 2500,
        anchorOrigin: {
            vertical: 'top',
            horizontal: isMobile ? 'center' : 'right',
        },
    });
};
