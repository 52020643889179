import { Box, Button, Link, Typography } from '@lingoda/ui';
import { Grid } from '@lingoda/ui/layout/GridNext';
import { trans } from '@lingoda/i18n';
import type { Path } from '@lingoda/router';
import {
    businessEnglishPath,
    corporatePath,
    loginPath,
    sectionRedirectPath,
} from '@lingoda/router';
import WaveSvg from './assets/wave.svg?component';
import CharactersSvg from './assets/characters.svg?component';
import HandleSvg from './assets/handle.svg?component';
import { DialogBubble, WaveWrapper, Wrapper } from './LayoutWrappers';
import type { ErrorLayoutProps } from './LayoutWrappers';

const links: Record<SectionName, Path> = {
    english: sectionRedirectPath('english'),
    french: sectionRedirectPath('french'),
    german: sectionRedirectPath('german'),
    spanish: sectionRedirectPath('spanish'),
    italian: sectionRedirectPath('italian'),
};

export const UnAuthenticatedLayout = ({ errorType }: ErrorLayoutProps) => {
    return (
        <Wrapper sx={{ height: { xs: '860px', md: '645px' } }}>
            <WaveWrapper top={{ xs: '-25%', md: '-50%' }}>
                <WaveSvg />
            </WaveWrapper>
            <Grid container spacing={2} position="absolute" py={5} px={6}>
                <Grid xs={12}>
                    <Typography variant="h2">
                        {trans('page-not-exists', {}, 'public-common')}
                    </Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="subtitle1">
                        {trans('page-not-exists-links', {}, 'public-common')}
                    </Typography>
                </Grid>
                <Grid xs={12} md={4}>
                    <Grid container spacing={2}>
                        {Object.entries(links).map(([name, path]) => (
                            <Grid xs={12} key={name}>
                                <Link to={path}>
                                    {trans(
                                        // If the type is used from generated types, we have to assing it manually
                                        // either global or al type instead of global one from generated.
                                        // if the type already came from our models SectionName type, it would work
                                        // So translations-extractor could see it and pass section values
                                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                                        `navigation.language-selector-${name as SectionName}`,
                                        {},
                                        'public-common',
                                    )}{' '}
                                    →
                                </Link>
                            </Grid>
                        ))}
                        <Grid xs={12}>
                            <Link to={businessEnglishPath()}>
                                {trans('navigation.business-english', {}, 'public-common')} →
                            </Link>
                        </Grid>
                        <Grid xs={12}>
                            <Link to={corporatePath()}>
                                {trans('navigation.corporate', {}, 'public-common')} →
                            </Link>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                        <Button color="primary" variant="contained" href={loginPath()}>
                            {trans('btn-back-to-login', {}, 'public-common')}
                        </Button>
                    </Grid>
                </Grid>
                <Grid xs={12} md={8}>
                    <Box position="relative" sx={{ transform: 'scale(0.7) translate(-25%, 0)' }}>
                        <Box
                            position="absolute"
                            sx={{
                                fontWeight: 700,
                                fontSize: { xs: '250px', md: '350px' },
                                color: 'white',
                                top: { xs: '170px', md: '100px' },
                                left: '5%',
                            }}
                        >
                            {errorType}
                        </Box>
                        <Box position="absolute">
                            <CharactersSvg width="100%" style={{ minWidth: '450px' }} />
                            <Box
                                sx={{
                                    transform: 'rotate(150deg)',
                                    position: 'absolute',
                                    left: '39%',
                                    top: '66%',
                                }}
                            >
                                <HandleSvg />
                            </Box>
                            <DialogBubble
                                sx={{
                                    transform: 'translate(-100%, 0)',
                                    left: { xs: '41%', md: '40%' },
                                    top: { xs: '64%', md: '60%' },
                                }}
                            >
                                {errorType === 404
                                    ? trans('notFound-page-not-exist', {}, 'public-common')
                                    : trans('notFound-mistake', {}, 'public-common')}
                            </DialogBubble>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: '74%',
                                    top: '79%',
                                }}
                            >
                                <HandleSvg />
                            </Box>
                            <DialogBubble
                                sx={{
                                    left: '78%',
                                    top: { xs: '77%', md: '74%' },
                                }}
                            >
                                {errorType === 404
                                    ? trans('notFound-mygoodness', {}, 'public-common')
                                    : trans('notFound-happens', {}, 'public-common')}
                            </DialogBubble>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Wrapper>
    );
};
