import type { UserResponse } from '@lingoda/api';
import type { Action } from '@lingoda/core';
import { createAction } from '@lingoda/core';
import type { User, UserId } from '../models';

export const addUser = createAction<User>('user/ADD_USER');

export const addUsers = createAction<User[]>('user/ADD_USERS');

export const removeUserData = createAction<UserId>('user/REMOVE_USER');

export const serverToState = createAction<UserResponse>('user/SERVER_TO_STATE');

export const updateUser = createAction<User>('user/UPDATE_USER');

type Payload = string;

export type VisitorUpdateTimzoneAction = Action<Payload>;

export const UPDATE_VISITOR_TIMEZONE = 'visitor/UPDATE_VISITOR_TIMEZONE';

export const updateVisitorTimezone = createAction<Payload>(UPDATE_VISITOR_TIMEZONE);
