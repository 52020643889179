import { type AcceptedLang, getLang, toUrlLang } from '@lingoda/env';
import { getBaseUri } from './getBaseUri';
import { getHost } from './getHost';
import { getRoute } from './getRoute';
import { getSchema } from './getSchema';
import { getClassroomDomain, getStudentDomain, getTeacherDomain } from './getAppByDomain';
import { type Params, removePlaceholders, replaceParams } from './replaceParams';
import type { Urls } from './urlsAll';
import type urls from './urlsAll';

export const getUrl = ((
    name: keyof typeof urls,
    params: Params = {},
    locale: AcceptedLang = getLang(),
): string => {
    const route = getRoute(name, locale);

    if (route.locale) {
        locale = route.locale;
    }

    params = {
        ...params,
        locale: toUrlLang(locale),
    };

    const path = removePlaceholders(replaceParams(route.path, params));

    let host: string;
    if (path.startsWith('/teacher/') || name.startsWith('teacherApp_')) {
        host = getTeacherDomain();
    } else if (name.startsWith('classroom_')) {
        host = getClassroomDomain();
    } else if (name.startsWith('studentApp_')) {
        host = getStudentDomain();
    } else {
        host = getHost();
    }

    const schema = getSchema(route.schema);

    return `${schema}//${host}${getBaseUri()}${path}`;
}) as Urls;
