import type { AnyAction, Reducer, ReducersMapObject } from 'redux';

// Wraps existing reducer and chains them
const wrapReducer =
    <T>(parent: Reducer<T>, child: Reducer<T>) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: T, action: AnyAction) =>
        child(parent(state, action), action);

export type Reducers = ReducersMapObject;
type Callback = (reducers: ReducersMapObject) => void;

const _asyncReducers: Reducers = {};

// Notify all listeners with the new async reducers
const listeners: Callback[] = [];

const notify = () => {
    for (const listener of listeners) {
        listener(_asyncReducers);
    }
};

// Add listener to async reducer changes
export const addListener = (listener: Callback) => {
    listeners.push(listener);
};

// Injects reducer into the async reducers list and notifies listeners
export const injectAsyncReducer = <T>(name: string, reducer: Reducer<T>) => {
    _asyncReducers[name] = _asyncReducers[name]
        ? wrapReducer(_asyncReducers[name], reducer)
        : reducer;

    notify();
};

export type { Reducer } from 'redux';

// Returns all current async reducers
export const asyncReducers = () => _asyncReducers;
