import { handleActionsImmutably as handleActions } from '../../store/handleActionsImmutably';
import { request, requestFailed, requestSuccessful } from '../actions';
import { RequestRecord, STATUS_FAILED, STATUS_PENDING, STATUS_SUCCESS } from '../models';
import type { Request } from '../models';

export type RequestsState = Readonly<GenericObject<Request>>;

type RequestAction = ReturnType<typeof request>;
type RequestSuccessfulAction = ReturnType<typeof requestSuccessful>;
type RequestFailedAction = ReturnType<typeof requestFailed>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reducer = handleActions<RequestsState, any>(
    {
        [`${request}`]: (state, { payload: { id, actionType } }: RequestAction) => {
            state[id] = RequestRecord({ id, actionType, status: STATUS_PENDING });

            return state;
        },
        [`${requestSuccessful}`]: (state, { payload }: RequestSuccessfulAction) => {
            if (!state[payload.id]) {
                return state;
            }
            state[payload.id].status = STATUS_SUCCESS;
            state[payload.id].response = payload.response;

            return state;
        },
        [`${requestFailed}`]: (state, { payload }: RequestFailedAction) => {
            if (!state[payload.id]) {
                return state;
            }
            state[payload.id].status = STATUS_FAILED;
            state[payload.id].response = payload.response;

            return state;
        },
    },
    {},
);
