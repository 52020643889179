import omit from 'lodash/omit';
import { getCookieValue, setCookieValue } from '@lingoda/cookie';
import { FeatureFlag } from '@lingoda/graphql';
import { type FeatureFlags } from './models';

const COOKIE_NAME = 'FEATURES';
let featuresUnparsedValue: string | undefined = undefined;
let features: FeatureFlags | undefined = undefined;

export const getFeatures = (): FeatureFlags => {
    const value = getCookieValue(COOKIE_NAME);
    if (features && value === featuresUnparsedValue) {
        return features;
    }

    featuresUnparsedValue = value;
    features = (value ? JSON.parse(value) : undefined) as FeatureFlags;

    return features;
};

export const hasFeature = (feature: FeatureFlag) => {
    const features = getFeatures();

    return features?.[feature] || false;
};

export const isFeatureKeyExistInCookie = (feature: FeatureFlag): boolean => {
    const features = getFeatures();

    return features?.[feature] !== undefined;
};

const updateFeature = (updateFeatures: FeatureFlags) => {
    const currentValues = getFeatures();
    const nextValues = { ...currentValues, ...updateFeatures };
    const keysToRemove = Object.keys(nextValues).filter(
        (key) => nextValues[key as FeatureFlag] === undefined,
    );
    const cleanFeatures = omit(nextValues, keysToRemove);

    setCookieValue(COOKIE_NAME, JSON.stringify(cleanFeatures));
};

export const clearUserFeatures = () => {
    updateFeature({
        [FeatureFlag.BookClassBeforeTrialStart]: undefined,
        [FeatureFlag.SkipCreditCardForTrialDirectPurchase]: undefined,
    });
};
