import { handleActionsImmutably } from '@lingoda/core';
import { addSection, addSections } from './actions';
import { SectionFactory } from './models';
import type { Section } from './models';

export type SectionsState = GenericObject<Section>;

type SectionDataAction = ReturnType<typeof addSection>;
type SectionsDataAction = ReturnType<typeof addSections>;

type Payload = SectionDataAction['payload'] & SectionsDataAction['payload'];

export default handleActionsImmutably<SectionsState, Payload>(
    {
        [`${addSection}`]: (
            state: SectionsState,
            { payload }: SectionDataAction,
        ): SectionsState => {
            state[payload.id] = SectionFactory(payload);

            return state;
        },
        [`${addSections}`]: (
            state: SectionsState,
            { payload }: SectionsDataAction,
        ): SectionsState =>
            payload.reduce((newState, section) => {
                newState[section.id] = SectionFactory(section);

                return newState;
            }, state),
    },
    {},
);
