import { createAction, makeActionTrackable } from '@lingoda/core';
import { type AcceptedLang } from '@lingoda/env';

export interface Changes {
    timezone: string | undefined;
    timeFormat: string | undefined;
    weekStart: number | undefined;
    locale?: AcceptedLang | undefined;
}

export default makeActionTrackable(
    createAction(
        'student/UPDATE_SETTINGS',
        (userId: number, studentId: number, changes: Changes) => ({
            userId,
            changes,
            studentId,
        }),
    ),
);
