import { httpGet } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type { LearningUnitSchema, LessonSchema, ModuleSchema } from './common';

interface LessonResponse {
    data: LessonSchema;
    included?: {
        learningUnits: LearningUnitSchema[];
        modules: ModuleSchema[];
    };
}

export const getLesson = (id: number): Promise<LessonResponse> =>
    httpGet(getUrl('api_lesson_show', { id }));
