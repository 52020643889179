import { request, requestFailed, requestSuccessful } from '../request';
import type { Store } from 'redux';
import type { ActionMeta } from './createAction';

interface Meta {
    id: string;
}

// Convenience utility to track request and dispatch request lifecycle methods
export const trackRequest = <A extends ActionMeta<unknown, M>, M extends Meta>(
    store: Store<unknown>,
    { type, meta: { id } }: A,
    promise: Promise<unknown>,
) => {
    store.dispatch(request(type, id));

    promise.then(
        (response) => store.dispatch(requestSuccessful(id, response)),
        (response) => store.dispatch(requestFailed(id, response)),
    );
};
