import { produce } from 'immer';
import { handleActions } from './handleActions';
import type { Draft } from 'immer';
import type { Reducer } from 'redux';
import type { ReducerMap } from './handleActions';

export type { Draft };

export const handleActionsImmutably = <S, A>(actions: ReducerMap<Draft<S>, A>, state: Draft<S>) =>
    produce(handleActions<Draft<S>, A>(actions, state)) as unknown as Reducer<S>;
