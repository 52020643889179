import { addYears, createDate, startOfDay } from '@lingoda/dates';
import { SortDirection, getDirection } from '@lingoda/utils';

interface TimelineOptions {
    offset?: number | null;
    limit?: number | null;
    sort?: string | null; // TODO: replace with SortDirection
    fromDate?: string | null;
    toDate?: string | null;
}

export const mergeTimelineHashList = <T>(
    existingHash: Record<string, T>,
    incomingList: T[],
    options: TimelineOptions,
    { getItemId }: { getItemId: (item: T) => string },
) => {
    const offset = options.offset || 0;
    // If loading was started from scratch, clear the cache to indicate 'fresh' loading:
    const nextHash: Record<string, T> = offset === 0 ? {} : { ...existingHash };

    incomingList.forEach((item) => {
        nextHash[getItemId(item)] = item;
    });

    return nextHash;
};

interface DateRangeOptions {
    fromDate?: string | null;
    toDate?: string | null;
}

export const filterTimelineItemsInDateRange = <T>(
    items: (readonly [T, number])[],
    options: DateRangeOptions,
) => {
    const todayStart = startOfDay(createDate());
    const fromDateTimestamp = (
        options.fromDate ? createDate(options.fromDate) : addYears(todayStart, -10)
    ).getTime();
    const toDateTimestamp = (
        options.toDate ? createDate(options.toDate) : addYears(todayStart, 10)
    ).getTime();

    const filteredList = items.reduce((acc, [item, timestamp]) => {
        if (fromDateTimestamp <= timestamp && timestamp <= toDateTimestamp) {
            acc.push(item);
        }

        return acc;
    }, [] as T[]);

    return filteredList;
};

export const readTimelineHashList = <T>(
    hash: Record<string, T>,
    options: TimelineOptions,
    { getTimestamp }: { getTimestamp: (item: T) => number },
) => {
    const offset = options.offset || 0;
    const limit = options.limit || Infinity;
    const sort = (options.sort || SortDirection.ASC) as SortDirection;

    // Build a list with precomputed timestamps:
    const list = Object.values(hash).map((item) => [item, getTimestamp(item)] as const);

    // Sort the list by timestamp:
    const sortedList = list.sort((a, b) => (a[1] - b[1]) * getDirection(sort));

    // Filter the list by fromDate and toDate:
    const filteredList = filterTimelineItemsInDateRange(sortedList, options);

    // Select a particular 'page' of the list with offset and limit:
    const splicedList = filteredList.splice(offset, limit);

    return splicedList;
};
