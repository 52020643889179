import { isFeatureKeyExistInCookie } from '@lingoda/feature-flags';
import { type FeatureFlag } from '@lingoda/graphql';
import { httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';

export const setDemandedFeatures = (requestedFeatures: FeatureFlag[]): Promise<void> => {
    const features = requestedFeatures.filter((feature) => !isFeatureKeyExistInCookie(feature));
    if (!features.length) {
        return Promise.resolve();
    }

    return httpPost(getUrl('api_feature_flags_decide_on_demand'), { features });
};
