import { Box, type BoxProps } from '../layout/Box';
import CircularProgress from './CircularProgress';

interface Props extends BoxProps {
    minHeight?: number;
    visibility?: 'hidden' | 'visible';
}

const Loader = ({ minHeight = 300, visibility = 'visible', ...props }: Props) => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight={minHeight}
        visibility={visibility}
        {...props}
    >
        <CircularProgress />
    </Box>
);

export default Loader;
