export type AllowedTypes = boolean | string | number | Record<string, unknown> | null | undefined;

export const getStorageValue = <T extends AllowedTypes>(key: string) => {
    try {
        const value = localStorage.getItem(key);

        try {
            return value === null ? value : (JSON.parse(value) as T);
        } catch {
            return value as T;
        }
    } catch (e) {
        if (e instanceof Error && e.name === 'NS_ERROR_FILE_CORRUPTED') {
            localStorage.clear();
        }
    }
};

export const setStorageValue = (key: string, value: unknown) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        if (e instanceof Error && e.name === 'NS_ERROR_FILE_CORRUPTED') {
            localStorage.clear();
        }
    }
};

export const removeStorageValue = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        if (e instanceof Error && e.name === 'NS_ERROR_FILE_CORRUPTED') {
            localStorage.clear();
        }
    }
};

export const getSessionValue = (key: string): AllowedTypes => {
    if (window) {
        const value = window.sessionStorage.getItem(key);

        if (value === null) {
            return value;
        }

        return JSON.parse(value);
    }
};

export const setSessionValue = (key: string, value: AllowedTypes) => {
    if (window) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    }
};

export const removeSessionValue = (key: string) => {
    if (window) {
        window.sessionStorage.removeItem(key);
    }
};

export const storage = window.localStorage;
