import memoize from 'lodash/memoize';

export default <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends (...args: any) => Promise<unknown>,
    V extends Parameters<typeof memoize>,
>(
    arg0: T,
    arg1?: V[0],
) => {
    const memoizedCall = memoize(arg0, arg1);

    return ((...args: Parameters<T>[]) => {
        const result = memoizedCall(...args);

        result.catch((e) => {
            memoizedCall.cache = new memoize.Cache();

            return e;
        });

        return result;
    }) as T;
};
