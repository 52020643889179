type RequestProvider = <T>(url: string, params: RequestInit) => Promise<T>;

let requestProvider: RequestProvider;

export const setRequestProvider = (newRequestProvider: RequestProvider) =>
    (requestProvider = newRequestProvider);

const request: RequestProvider = (...args) => requestProvider(...args);

export default request;
