import { getLang, toUrlLang } from '@lingoda/env';
import { getPath, getRawPath, getUrl } from '@lingoda/urls';
import type { Path } from './types';
import type { LocaleType, RouterPathFunction } from './utils';

const currentLocale = getLang();

interface PathTransOptions<P> {
    defaultParams?: P;
    absolute?: boolean;
}

export const pathTrans = <P = void>(
    routeName: Parameters<typeof getRawPath>[0],
    { defaultParams = {} as P, absolute = false }: PathTransOptions<P> = {},
) => {
    const raw = getRawPath(routeName, currentLocale);

    const fn: RouterPathFunction<P> = (params: P = defaultParams, locale = currentLocale): Path => {
        (params as LocaleType).locale = toUrlLang(locale);

        // In case locale doesn't change, we provide path. Otherwise provide full url.
        if (!absolute && (!locale || locale === currentLocale)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            return getPath(/* @ignore */ routeName as any, params, locale);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
        return getUrl(/* @ignore */ routeName as any, params, locale);
    };

    fn.raw = raw;

    return fn;
};
