import { ClassType } from '@lingoda/graphql';
import type { TypePolicy } from '@apollo/client';

const PackageVariant: TypePolicy = {
    keyFields: ['id', 'subTotal', ['currency'], 'sectionCurriculum', ['section', ['name']]],
    fields: {
        classType: (_, { readField }) => {
            const credits = readField<number>('groupCredits');

            return credits && credits > 0 ? ClassType.Group : ClassType.Individual;
        },
    },
};

export default PackageVariant;
