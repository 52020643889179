import type { Action, ActionMeta } from './createAction';

export interface UserInfoExtra {
    meta: {
        currentUser?: {
            userId: number;
            studentId: number;
            studentInfoId?: number;
        };
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UserInfoActionCreator<P, A extends Action<P>, F extends (...args: any[]) => A> = (
    ...args: Parameters<F>
) => ReturnType<F> & UserInfoExtra;

export const isActionWithCurrentUserInfo = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): action is ActionMeta<unknown, UserInfoExtra['meta']> =>
    typeof action === 'object' && typeof action.meta === 'object' && !!action.meta.currentUser;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addCurrentUserInfo = <F extends (...args: any[]) => any>(actionCreator: F) => {
    type A = ReturnType<F>;
    type Payload = A extends Action<infer Pl> ? Pl : never;
    const newActionCreator: UserInfoActionCreator<Payload, A, F> = (...args) => {
        const action = actionCreator(...args);
        action.meta = action.meta || {};
        action.meta.currentUser = {};

        return action;
    };

    newActionCreator.toString = () => actionCreator.toString();

    return newActionCreator;
};
