import merge from 'lodash/merge';
import type { UserResponse } from '@lingoda/api';
import { combineActions, handleActionsImmutably } from '@lingoda/core';
import {
    addUser,
    addUsers,
    removeUser,
    updateNotifications,
    updateSettings,
    updateUser,
} from '../actions';
import { UserFactory } from '../models';
import type { User } from '../models';

export type State = GenericObject<User>;

type UserAction = ReturnType<typeof updateUser>;
type RemoveUserAction = ReturnType<typeof removeUser>;
type AddUserAction = ReturnType<typeof addUser>;
type UsersAction = ReturnType<typeof addUsers>;
type UpdateSettingsAction = ReturnType<typeof updateSettings.success>;

type Payload = UserAction['payload'] &
    RemoveUserAction['payload'] &
    UsersAction['payload'] &
    UpdateSettingsAction['payload'];

export default handleActionsImmutably<State, Payload>(
    {
        [`${addUser}`]: (state: State, { payload }: AddUserAction) => {
            state[payload.id] = UserFactory(payload);

            return state;
        },
        [`${removeUser}`]: (state: State, { payload }: RemoveUserAction) => {
            delete state[payload];

            return state;
        },
        [`${addUsers}`]: (state: State, { payload }: UsersAction): State => {
            (payload || []).forEach((user) => (state[user.id] = UserFactory(user)));

            return state;
        },

        [`${updateUser}`]: (state: State, { payload }: UserAction) => {
            state[payload.id] = merge(state[payload.id], payload);

            return state;
        },
        [`${combineActions(updateSettings.success, updateNotifications.success)}`]: (
            state: State,
            { payload: { result } }: UpdateSettingsAction,
        ) => {
            const user = (result as UserResponse).data;

            state[user.id] = UserFactory({
                ...state[user.id],
                timezone: user.timezone,
                marketingAgreement: user.marketingAgreement,
            });

            return state;
        },
    },
    {},
);
