import { EventEmitter } from 'fbemitter';
import type { ErrorMessage, ErrorResponse } from './error';

export const messagesEvent = new EventEmitter();

const getContent = async (response: Response) => {
    const contentType = response.headers.get('content-type');

    if (response.status === 204) {
        return;
    } else if (contentType?.startsWith('application/json')) {
        return response.json();
    }

    throw response;
};

const checkStatus = <T>(status: number, content: T): T => {
    if (status >= 200 && status < 300) {
        return content;
    }

    // eslint-disable-next-line no-throw-literal
    throw {
        ...content,
        status,
    };
};

const parseMessages = async <T>(response: Response) => {
    const content = await getContent(response);
    if (content && content.messages) {
        messagesEvent.emit('added', content.messages);
    }

    return content as T;
};

export const parseJSON = async <T>(response: Response) => {
    const content = await parseMessages<T>(response);

    return checkStatus<T>(response.status, content);
};

export const getErrorMessages = (response: ErrorResponse): Promise<Messages> => {
    throw response && response.messages;
};

export const getErrorMessage = (
    response: ErrorResponse | undefined,
    key: string,
): string | undefined => {
    if (!response || !response.messages) {
        return undefined;
    }

    const keyMessages: ErrorMessage[] | undefined = response.messages[key];
    if (!keyMessages || !keyMessages[0]) {
        return undefined;
    }

    return keyMessages[0].message;
};

interface Message {
    message: string;
    type: 'error' | 'warning' | 'info' | 'success';
}

export interface Messages {
    [key: string]: Message[];
}

export interface ApiResponse<T, I> {
    data: T;
    included?: I;
    messages?: Messages;
}

export const hasApiErrorMessages = <T extends object>(
    data: T,
): data is T & { messages: Messages } => Boolean((data as T & { messages?: Messages }).messages);
