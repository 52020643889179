import { alpha } from '@mui/system/colorManipulator';

export const fadeColor = <K extends number>(color: string, ...alphasList: K[]) =>
    alphasList.reduce(
        (newColor, alphaKey) => ({
            ...newColor,
            [alphaKey]: alpha(color, alphaKey / 100),
        }),
        {} as Record<K, string>,
    );

export const dvh = (dvhString: string) => {
    const fallbackValue = dvhString.replace(/dvh/g, 'vh');

    if (fallbackValue === dvhString) {
        return dvhString;
    }

    return [fallbackValue, dvhString];
};

export const dvw = (dvwString: string) => {
    const fallbackValue = dvwString.replace(/dvw/g, 'vw');

    if (fallbackValue === dvwString) {
        return dvwString;
    }

    return [fallbackValue, dvwString];
};

export { alpha };
