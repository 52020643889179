import type { Date } from '@lingoda/dates';
import { createDate, diff } from '@lingoda/dates';
import type { Action as ActionType } from './createAction';

type Action = ActionType<unknown>;

interface CacheMeta {
    time: Date;
}

interface CacheData {
    value: unknown;
    meta: CacheMeta;
}

interface Cache {
    [key: string]: CacheData;
}

const cache: Cache = {};

const memoiseActionKey = ({ type, payload }: { type: string; payload?: unknown }) =>
    JSON.stringify({
        payload,
        type,
    });

export const isPromise = (object: unknown): object is Promise<unknown> =>
    Promise.resolve(object) === object;

export const clearActionCache = (
    action: Action,
    resolver: (cacheInfoDetails: CacheMeta) => unknown,
) => {
    const key = memoiseActionKey(action);
    if (cache[key] && resolver(cache[key].meta)) {
        delete cache[key];
    }

    return action;
};

export const ttl = (action: Action, expireTime: number) =>
    clearActionCache(action, ({ time }) => diff(time, createDate()) > expireTime);

export const memoiseAction = <T>(action: Action, resolver: () => T): T => {
    const key = memoiseActionKey(action);
    if (!(key in cache)) {
        const value = resolver();
        cache[key] = {
            value,
            meta: { time: createDate() },
        };

        if (isPromise(value)) {
            value.catch(() => {
                delete cache[key];
            });
        }
    }

    return cache[key].value as T;
};
