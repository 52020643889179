interface DangerousHtmlComponent {
    <C extends React.ElementType = 'div'>(
        props: React.ComponentPropsWithRef<C> & {
            as?: C;
            children?: string;
        },
    ): JSX.Element;
}

const DangerousHtml: DangerousHtmlComponent = ({ as = 'div', children, ...props }) => {
    const Component = as;

    return <Component dangerouslySetInnerHTML={{ __html: children }} {...props} />;
};

export default DangerousHtml;
