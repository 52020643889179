import { getSchemaHost } from './getSchemaHost';

let baseUri: string;

export const getBaseUri = () => {
    if (!window || !window.location) {
        return '';
    }

    if (!window.document || !window.document.baseURI) {
        return '';
    }

    if (!baseUri) {
        const docBase =
            window.document.baseURI !== 'about:blank' ? window.document.baseURI : 'about://';

        baseUri = docBase
            .replace(new RegExp(`^${getSchemaHost()}`), '') // strip schema and host
            .replace(/\/$/, ''); // strip trailing slash
    }

    return baseUri;
};
