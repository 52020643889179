import {
    Alert,
    type AlertColor,
    type AlertProps,
    colorsPalette,
    shadowElevationsFactory,
    styled,
} from '@lingoda/ui';

export type ToastAlertProps = Omit<AlertProps, 'elevation' | 'variant'>;

export const ToastAlert = (props: ToastAlertProps) => {
    return <AlertStyled {...props} variant="outlined" />;
};

const AlertStyled = styled(Alert)(({ severity = 'success' }) => ({
    width: 392,
    boxShadow: shadowElevationsFactory.level2(...shadowColors[severity]),
    margin: '0 auto',
}));

const shadowColors: Record<AlertColor, [string, string]> = {
    success: [colorsPalette.positiveAlpha[7], colorsPalette.positiveAlpha[12]],
    info: [colorsPalette.blackAlpha[8], colorsPalette.blackAlpha[12]],
    warning: [colorsPalette.infoAlpha[7], colorsPalette.infoAlpha[12]],
    error: [colorsPalette.negativeAlpha[8], colorsPalette.negativeAlpha[12]],
    promotion: [colorsPalette.purpleAlpha[8], colorsPalette.purpleAlpha[12]],
};
