import { RestLink } from 'apollo-link-rest';
import { getPath, getSchemaHost } from '@lingoda/urls';

export default () =>
    new RestLink({
        uri: getSchemaHost(),
        credentials: 'include',
        responseTransformer: async (response: Response) =>
            response.json().then((responseJson) => responseJson),
        customFetch: (path, options) =>
            fetch(getPath(path as Parameters<typeof getPath>[0]), options),
    });
