import { injectAsyncReducer } from '@lingoda/core';
import { STORE_KEY, USERS_STORE_KEY } from './constants';
import authReducers from './reducers/authReducers';
import usersReducers from './reducers/usersReducers';
import initSideEffects from './side-effects';

export { default as init } from './init';
export { default as authReducers } from './reducers/authReducers';
export { default as usersReducers } from './reducers/usersReducers';
export * from './constants';
export * from './token';
export * from './actions';
export * from './models';
export * from './selectors';
export * from './utils';
export * from './types';

export { default as getAuthTokenExpiry } from './getAuthTokenExpiry';
// adding the auth headers to http requests

injectAsyncReducer(STORE_KEY, authReducers);
injectAsyncReducer(USERS_STORE_KEY, usersReducers);

export const COOKIE_REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';

initSideEffects();
