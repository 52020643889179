declare let __APP__: 'student' | 'storybook' | 'classroom';
export const getApp = () => __APP__;

export { getLang, getDashLang, getUrlLang, toUrlLang, mapUrlToLangCode } from './getLang';
export {
    acceptedLangs,
    LANG_DEFAULT,
    fullySupportedLangs,
    partiallySupportedLangs,
} from './models';
export type { AcceptedLang, UrlLang } from './models';
export * from './routeLabelsForPartiallySupportedLangs';
export * from './helpers';
