import { toMarketingUrl } from '@lingoda/urls';
import { UrlProvider } from './types';
import type { RouterPathFunction } from './utils';

export const withMarketingUrl = <T extends RouterPathFunction<never>>(pathFn: T) => {
    type Result = T extends RouterPathFunction<infer Params> ? Params : never;

    const newPathFn: RouterPathFunction<Result> = (...params) => {
        const path = (pathFn as unknown as RouterPathFunction<Result>)(...params);
        if (typeof path === 'string') {
            return toMarketingUrl(path);
        }

        return {
            provider: UrlProvider.External,
            href: toMarketingUrl(path.href),
        };
    };

    newPathFn.raw = pathFn.raw;

    return newPathFn;
};
