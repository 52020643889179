import { buildUrl } from './buildUrl';

export interface Params {
    [key: string]: string;
}

export const replaceParams = (url: string, params: Params) => {
    const query: Params = {};
    Object.keys(params).forEach((key: string) => {
        if (!params[key]) {
            // Skip params without value
            return;
        }

        if (url.includes(`:${key}`)) {
            url = url.replace(`:${key}`, params[key]);
        } else if (!['locale', 'hash'].includes(key)) {
            query[key] = params[key];
        }
    });

    return buildUrl(url, {
        query: Object.getOwnPropertyNames(query).length ? query : undefined,
        hash: params.hash,
    });
};

export const removePlaceholders = (url: string) => url.replace(/\/:\w+/g, '');
