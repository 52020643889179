import { ExerciseStatus, type ExerciseType, type H5PExercise } from '@lingoda/graphql';
import type { FieldPolicy } from '@apollo/client';
import type { FieldReadFunction } from '@apollo/client/cache/inmemory/policies';

const exercises:
    | FieldPolicy<ReadonlyArray<H5PExercise>>
    | FieldReadFunction<ReadonlyArray<H5PExercise>> = (_, { readField, toReference, args }) => {
    let exerciseRefs =
        readField<H5PExercise[]>(
            'exercises',
            toReference({
                __typename: 'PracticeItem',
                lesson: {
                    id: args?.lessonId,
                },
            }),
        ) || [];

    if (args?.type) {
        exerciseRefs = exerciseRefs.filter(
            (item) => readField<ExerciseType>('type', item) === args?.type,
        );
    }

    if (args?.status) {
        exerciseRefs = exerciseRefs.filter((item) => {
            const score = readField<number>('score', item);

            return args?.status === ExerciseStatus.Pending ? score === null : score !== null;
        });
    }

    return exerciseRefs;
};

export default exercises;
