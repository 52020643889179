import { getApp } from '@lingoda/env';
import { isAbsolute } from '@lingoda/urls';
import history from './history';
import resolvePath from './resolvePath';
import { UrlProvider } from './types';
import type { Path } from './types';

interface Options {
    replace?: boolean;
}

function navigateTo(url: string, options?: Options): void {
    if (options?.replace) {
        window.location.replace(url);

        return;
    }
    window.location.href = url;
}

const navigateInternalPath = (path: Path, options?: Options) => {
    if (options?.replace) {
        history.replace(resolvePath(path));

        return;
    }
    history.push(resolvePath(path));
};

export default (path: Path, options?: Options) => {
    if (typeof path === 'string') {
        if (isAbsolute(path)) {
            navigateTo(path, options);
        } else {
            navigateInternalPath(path, options);
        }

        return;
    }

    switch (path.provider) {
        case UrlProvider.External:
            navigateTo(path.href, options);
            break;
        case UrlProvider.Student:
            if (getApp() === 'student') {
                navigateInternalPath(path, options);
            } else {
                navigateTo(resolvePath(path), options);
            }
            break;
    }
};
