import {
    type ApolloError,
    type FieldPolicy,
    type OperationVariables,
    type Reference,
    type ServerError,
} from '@apollo/client';
import { type ErrorMessages } from '@lingoda/utils';
import type { FormError } from '@lingoda/utils/forms/buildFormErrors';
import type * as ApolloReactHooks from '../hooks';

export * from './cache';
export * from './endpoints';
export * from './error';

export const getGQLNetworkMessages = (error: unknown): ErrorMessages | undefined =>
    (((error as ApolloError)?.networkError as ServerError)?.result as FormError)?.messages;

export const pagination = (): FieldPolicy<ExistingType, IncomingType> => ({
    merge(existing, incoming, { readField }) {
        const items = existing ? { ...existing.items } : {};
        incoming.items.forEach((item: Reference) => {
            items[readField('id', item) as number] = item;
        });

        return {
            ...incoming,
            items,
        };
    },
    read(existing) {
        if (existing) {
            return {
                ...existing,
                items: Object.values(existing.items),
            };
        }
    },
});

type ExistingType = { items: Record<number, Reference> };
type IncomingType = { items: Reference[] };

// Return { skip } when not all variables fields are non nullable
export const skipNullQueryVariables = <Q, V extends OperationVariables>(
    baseOptions: ApolloReactHooks.QueryHookOptions<Q, V>,
) => {
    if (baseOptions?.variables && hasAllValues(baseOptions.variables)) {
        return baseOptions as ApolloReactHooks.QueryHookOptions<Q, NonNullableFields<V>> & {
            variables: V;
        };
    }

    return {
        skip: true,
    };
};

const hasAllValues = <T extends Record<string, unknown>>(obj: T): obj is NonNullableFields<T> =>
    !Object.values(obj).some(isNullable);

const isNullable = <T>(value: T) => value === undefined || value === null;

type NonNullableFields<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};
