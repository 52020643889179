import { Fragment, createElement } from 'react';
import { trans } from '../../trans';
import { filterStringParams, isPossibleHtml, renderPlaceholders } from './common';
import type { Props } from './common';

export type { Props as TranslateProps } from './common';

/**
 * @deprecated
 *
 * If you need to pass custom component into translation use: `FormattedMessage` from `@lingoda/i18n`.
 * Read more: https://formatjs.io/docs/react-intl/components#rich-text-formatting.
 *
 * If you need to render raw HTML from translation:
 * 1) think twice because it's not best practice;
 * 2) consider using `FormattedMessage` with custom tag;
 * 3) use `dangerouslySetInnerHTML`.
 *
 * If you not need anything fancy, just use `trans` from `@lingoda/i18n`.
 */
export const Translate: React.FC<Props> = ({
    id,
    params,
    domain,
    component,
    ...remainingProps
}) => {
    // We first filter the string params and translate them using regular translate function
    const stringParams = filterStringParams(params);

    const translation = trans(/* @ignore */ id, stringParams, domain);
    const Comp = component || 'span';
    let view = renderPlaceholders(translation, params);

    const props: React.HTMLAttributes<HTMLElement> = remainingProps;

    if (view.length === 1) {
        const child = view[0];
        if (typeof child === 'string' && isPossibleHtml(child)) {
            props.dangerouslySetInnerHTML = { __html: child };
            props.className = 'translated-item';
            // If we use `dangerouslySetInnerHTML`, we can't have children
            view = undefined as never;
        }
    }

    return createElement(Fragment, {}, createElement(Comp, props as React.Attributes, view));
};
