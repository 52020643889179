import { httpPost } from '@lingoda/http';
import { getUrl } from '@lingoda/urls';
import type { SectionName } from '@lingoda/sections';

export interface FetchTokenData {
    username: string;
    password: string;
}

export interface FetchTokenResponse {
    token: string;
    refresh_token: string;
}

interface LoginData {
    username: string;
    password: string;
    rememberMe: boolean;
}

export interface LoginResponse {
    success: boolean;
    redirectURL: string;
    data: {
        user: {
            section?: SectionName;
        };
    };
}

export const fetchToken = ({ username, password }: FetchTokenData): Promise<FetchTokenResponse> =>
    httpPost(
        getUrl('api_login_check'),
        {
            username,
            password,
        },
        {
            headers: {
                'X-TOKEN-AS': 'payload',
            },
        },
    );

export const refreshToken = (refreshToken?: string): Promise<FetchTokenResponse> =>
    httpPost(
        getUrl('gesdinet_jwt_refresh_token'),
        refreshToken
            ? {
                  REFRESH_TOKEN: refreshToken,
              }
            : undefined,
    );

export const login = ({ username, password, rememberMe }: LoginData): Promise<LoginResponse> =>
    httpPost(getUrl('user_security_check'), {
        _username: username,
        _password: password,
        _remember_me: rememberMe,
    });
