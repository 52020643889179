let loaded: Unpacked<ReturnType<typeof fetchAnalytics>> | undefined;

const fetchAnalytics = async () => (await import('@aws-amplify/analytics')).default;

export const loadAnalytics = async () => {
    if (!loaded) {
        try {
            loaded = await fetchAnalytics();
        } catch {
            loaded = undefined;
        }
    }

    return loaded;
};
