import { httpGet } from '@lingoda/http';
import { buildUrl, getUrl } from '@lingoda/urls';
import type { SectionName } from '@lingoda/sections';
import type {
    CurriculumSchema,
    ModuleSchema,
    StartingModuleSchema,
    StudentModuleSchema,
} from './common';

interface ModulesResponse {
    data: ModuleSchema[];
    included?: {
        curricula?: CurriculumSchema[];
    };
}

interface StudentModulesResponse {
    data: StudentModuleSchema[];
}

interface StartingModulesResponse {
    data: StartingModuleSchema[];
}

interface ModulesQueryParams {
    curriculumId: number;
    section: SectionName;
}

export const getModules = (query?: ModulesQueryParams): Promise<ModulesResponse> =>
    httpGet(buildUrl(getUrl('api_modules_index'), query ? { query } : undefined));

export const getStudentModules = (studentId: number): Promise<StudentModulesResponse> =>
    httpGet(getUrl('api_students_modules_index', { student_id: studentId }));

export const getStartingModules = (studentId: number): Promise<StartingModulesResponse> =>
    httpGet(getUrl('api_students_starting_modules_index', { student_id: studentId }));
