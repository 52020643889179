export const acceptedLangs = ['en', 'de', 'ru', 'fr', 'es', 'it', 'pl', 'tr', 'pt_BR'] as const;
export type AcceptedLang = (typeof acceptedLangs)[number];

export type UrlLang =
    | 'en'
    | 'de'
    | 'ru'
    | 'fr'
    | 'es'
    | 'it'
    | 'pl'
    | 'tr'
    | 'br' /* instead of 'pt_BR' */;

export const fullySupportedLangs: ReadonlyArray<AcceptedLang> = ['en', 'de', 'ru', 'fr', 'es'];
export const partiallySupportedLangs: ReadonlyArray<AcceptedLang> = ['it', 'pl', 'tr', 'pt_BR'];

export const LANG_DEFAULT = 'en';
